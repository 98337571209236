import React, { useEffect, useRef } from "react"
import { Box, CircularProgress, FormControlLabel, Switch } from "@mui/material"
import { useIsMutating } from "react-query"

const color = {
    active: "#33ca51",
    inactive: "#dd4848",
    pending: "gray",
}

function StatusButton({ status, onChange, loading }) {
    const pending = useRef(false)

    const mutations = useIsMutating()

    useEffect(() => {
        if (!loading || mutations !== 0) pending.current = false
    }, [loading, mutations])

    const handleChange = (e) => {
        const data = e.target.checked
        onChange(data)
        pending.current = true
    }
    // I disable state should be updated on the next rerender
    return (
        <Box
            sx={{
                width: "fit-content",
                height: "fit-content",
                position: "relative",
            }}
        >
            <CircularProgress
                size="1.5em"
                sx={{
                    position: "absolute",
                    top: ".7em",
                    left: "9ch",
                    fontWeight: "bold",
                    color: "gray",
                    display: pending.current ? "block" : "none",
                }}
            />
            <FormControlLabel
                labelPlacement="start"
                label={status}
                checked={status === "ACTIVE"}
                disabled={pending.current}
                onChange={handleChange}
                control={
                    <Switch
                        disableRipple
                        disabled={pending.current}
                        size="small"
                        sx={{
                            "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": {
                                    "& + .MuiSwitch-track": {
                                        backgroundColor: "#c3eacb",
                                    },
                                    "& .MuiSwitch-thumb": {
                                        color: color.active,
                                    },
                                    "&.Mui-disabled + .MuiSwitch-thumb": {
                                        backgroundColor: "lightseagreen",
                                        color: "lightgreen",
                                        opacity: 0.6,
                                    },
                                },
                            },
                            "& .MuiSwitch-thumb": {
                                color: "#f86049b5",
                            },
                            "& .MuiSwitch-track": {
                                backgroundColor: "#d9acac",
                            },
                        }}
                    />
                }
                sx={{
                    backgroundColor:
                        status === "ACTIVE" ? "#e5ffea" : "#ffd6d6",
                    opacity: pending.current ? 0.4 : 1,
                    color: status === "ACTIVE" ? color.active : "#f86049b5",
                    px: "1em",
                    py: ".5em",
                    borderRadius: 1,
                    width: "17ch",
                    fontWeight: 900,
                }}
            />
        </Box>
    )
}

export default StatusButton
