import React, {
    useState,
    useRef,
    useCallback,
    useEffect,
    useMemo,
    useContext,
} from "react"
import MaterialReactTable from "material-react-table"
import { Delete, Edit, Visibility } from "@mui/icons-material"
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { DateTime } from "luxon"
import { useMutation, useQueryClient } from "react-query"
import CreateRoles from "./createRoles.jsx"
import StatusButton from "../../Component/statusButton.jsx"
import ViewRoleDetails from "./viewRoleDetails.jsx"
import customeFilter from "../../Component/customFilterPanel.jsx"
import EditRoles from "./editRoles.jsx"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import useTableState from "../../Hooks/useTableState.js"
import { axiosPrivate } from "../../Utils/Api/axios.js"
import AuthContext from "../../Context/auth_provider.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import useTableProps from "../../Hooks/useTableProps.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import useBreakPoints from "../../Hooks/useBreakPoints.js"

// Roles List View Component
function Roles() {
    const {
        rolesCreate,
        rolesList,
        rolesDelete,
        rolesDetail,
        rolesPage,
        rolesStatusUpdate,
        rolesUpdate,
        getAllPermissions,
    } = useUserPermissions()

    const { md } = useBreakPoints()
    // USESTATES & USEREFS
    const [open, setOpen] = useState(false)
    const [update, setUpdate] = useState(false)
    const [view, setView] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const roleName = useRef()
    const roleData = useRef({})

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    const queryClient = useQueryClient()
    const { companyId } = useContext(AuthContext)

    // MUTATION
    const {
        mutate,
        data: statusUpdate,
        isLoading: updateStatusIsLoading,
        error: statusError,
    } = useMutation(
        (data) =>
            axiosPrivate
                .patch(`/corporate/${companyId}/roles/${data.id}`, {
                    id: data?.id,
                    status: data?.status,
                })
                .then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("roles")
            },
        }
    )
    const {
        mutate: deleteRole,
        data: deleteRoleResponse,
        isLoading: deleteIsLoading,
        error: deleteError,
    } = useMutation((id) =>
        axiosPrivate.delete(`/corporate/${companyId}/roles/${id}`)
    )

    // EVENT HANDLERS & HELPERS
    const handelView = (role) => {
        roleData.current = role
        setView(true)
        return roleData.current
    }
    const handleUpdate = (role) => {
        roleData.current = role
        setUpdate(true)
        return roleData.current
    }
    const handleClick = (role) => {
        setOpenDialog(true)
        roleName.current = role.name
    }
    const handleSwitch = useCallback(
        (role) => {
            roleName.current = role.id
            roleData.current = {
                ...roleData.current,
                id: role.id,
                status: role.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
            }
            mutate(roleData.current)
        },
        [mutate]
    )
    const handleModalClose = () => {
        setOpen(false)
    }
    const handleEditModalClose = () => {
        setUpdate(false)
    }
    const handleViewModalClose = () => {
        setView(false)
    }
    const getStatus = useCallback(
        ({ cell }) => (
            <StatusButton
                loading={updateStatusIsLoading}
                status={cell.getValue()}
                onChange={() => handleSwitch(cell?.row?.original)}
            />
        ),
        [handleSwitch, updateStatusIsLoading]
    )
    const actions = useCallback(
        ({ cell }) => (
            <Box sx={{ flex: 1 }}>
                <Button
                    disabled={!(rolesDetail || getAllPermissions)}
                    sx={{
                        minWidth: 24,
                        p: 1,
                        color: "primary.main",
                    }}
                    onClick={() => handelView(cell?.row?.original)}
                >
                    <Visibility />
                </Button>
                <Button
                    disabled={
                        !(rolesUpdate || getAllPermissions || rolesDetail)
                    }
                    sx={{ minWidth: 24, p: 0, color: "primary.main" }}
                    onClick={() => handleUpdate(cell?.row?.original)}
                >
                    <Edit />
                </Button>
                <Button
                    disabled={!rolesDelete}
                    sx={{ minWidth: 24, p: 0, color: "red" }}
                    onClick={() => {
                        handleClick(cell?.row?.original)
                        roleData.current = cell?.row?.original
                    }}
                >
                    <Delete />
                </Button>
                <div>
                    <Dialog
                        id="delete-roles-by-id"
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        BackdropProps={{ invisible: true }}
                        sx={{
                            "& .MuiDialog-paper": {
                                boxShadow: "0px 1px 1px 1px grey",
                            },
                        }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Confirm delete operation
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want delete role: `
                                {roleName.current}`?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setOpenDialog(false)}
                                sx={{
                                    bgcolor: "black",
                                    color: "white",
                                    ":hover": {
                                        bgcolor: "black",
                                        color: "white",
                                    },
                                    textTransform: "none",
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    bgcolor: "red",
                                    color: "white",
                                    ":hover": {
                                        bgcolor: "red",
                                        color: "white",
                                    },
                                    textTransform: "none",
                                }}
                                type="submit"
                                onClick={() => deleteRole(roleData.current?.id)}
                                disabled={deleteIsLoading}
                            >
                                Delete
                                {deleteIsLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "#fff",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
        ),
        [
            deleteIsLoading,
            deleteRole,
            openDialog,
            rolesDelete,
            rolesDetail,
            rolesUpdate,
            getAllPermissions,
        ]
    )
    const customDateFilter = useCallback(
        (props) => <CustomeDateFilter {...props} />,
        []
    )
    const handleProgramsExport = (data) =>
        data?.map((el) => ({
            ...el,
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            updated_at: DateTime.fromISO(el.updated_at).toFormat("ff"),
        }))

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 220,
                id: "created_at",
                accessorFn: (row) =>
                    DateTime.fromISO(row.created_at).toFormat("ff"),
                header: "Created At",
                filterVariant: "date",
                Filter: customDateFilter,
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 220,
                id: "updated_at",
                accessorFn: (row) =>
                    DateTime.fromISO(row.updated_at).toFormat("ff"),
                header: "Updated At",
                filterVariant: "date",
                Filter: customDateFilter,
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["ACTIVE", "INACTIVE"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                accessorKey: "actions",
                header: "Actions",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions, customDateFilter, getStatus]
    )

    const { params, state, onChangeHandlers } = useTableState({
        columns: columns,
    })

    const { isLoading, isFetching, data, refetch } = useFetchEntitiesPerPage(
        {
            endPoint: "roles",
            ...params,
        },
        { enabled: Boolean(rolesList) }
    )

    const { props } = useTableProps({
        columns: columns,
        exportProps: {
            dataEndPoint: "roles",
            model: params.filterModel,
            formatter: handleProgramsExport,
        },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
            columnVisibility: {
                status: Boolean(rolesStatusUpdate),
            },
        },
    })
    // USEEFFECT
    useEffect(() => {
        if (statusUpdate) {
            enqueueSnackbar(`Succesfully changed status.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            refetch()
        }
    }, [statusUpdate, enqueueSnackbar, refetch])
    useEffect(() => {
        if (statusError) {
            enqueueSnackbar(
                statusError?.response?.data?.error?.message ||
                    statusError?.message ||
                    "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            )
        }
    }, [statusError, enqueueSnackbar])
    useEffect(() => {
        if (deleteRoleResponse) {
            enqueueSnackbar(`Succesfully deleted.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            refetch()
            setOpenDialog(false)
        }
    }, [deleteRoleResponse, enqueueSnackbar, refetch])
    useEffect(() => {
        if (deleteError) {
            enqueueSnackbar(
                deleteError?.response?.data?.error?.message ||
                    deleteError?.message ||
                    "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            )
            setOpenDialog(false)
        }
    }, [deleteError, enqueueSnackbar])

    if (!rolesPage) return <RestrictedAccess />

    // RENDERING
    return (
        <Container
            component="main"
            disableGutters
            maxWidth="xl"
            sx={{
                backgroundColor: "#fff",
                minHeight: "100%",
            }}
        >
            <Box sx={{ width: "100%" }}>
                <Box sx={{ height: "100%" }}>
                    {open && (
                        <CreateRoles
                            handleModalClose={handleModalClose}
                            refetch={refetch}
                            open={open}
                        />
                    )}
                    {update && rolesUpdate && (
                        <EditRoles
                            handleEditModalClose={handleEditModalClose}
                            roleData={roleData.current}
                            refetch={refetch}
                            update={update}
                        />
                    )}
                    {view && rolesDetail && (
                        <ViewRoleDetails
                            handleViewModalClose={handleViewModalClose}
                            roleData={roleData.current}
                            view={view}
                        />
                    )}
                    <Box sx={{ flex: 1, height: "100%" }}>
                        <MaterialReactTable
                            data={data?.data?.data ?? []}
                            initialState={{
                                columnPinning: {
                                    right: [md ? "actions" : null],
                                },
                            }}
                            {...props}
                            {...onChangeHandlers}
                            renderTopToolbarCustomActions={() => (
                                <Box
                                    sx={{
                                        display: "flex",
                                    }}
                                >
                                    {rolesCreate && getAllPermissions && (
                                        <Button
                                            size="small"
                                            sx={{
                                                bgcolor: "primary.main",
                                                color: "white",
                                                px: 2,
                                                mx: 1,
                                                boxShadow: 2,
                                                " :hover": {
                                                    bgcolor: "primary.main",
                                                    color: "white",
                                                },
                                            }}
                                            onClick={() => setOpen(true)}
                                        >
                                            Add Role
                                        </Button>
                                    )}
                                </Box>
                            )}
                            muiTableContainerProps={{
                                sx: { maxHeight: "64vh" },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
export default Roles
