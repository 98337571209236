import React, { useState } from "react"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import { CheckCircleOutline, ContentCopyOutlined } from "@mui/icons-material"
import { DateTime } from "luxon"

// View Voucher Details Component
function VoucherDetails({ handleClose, open, voucherData }) {
    const [copy, setCopy] = useState(false)

    const expired =
        DateTime.now().startOf("hour") >
        DateTime.fromISO(voucherData?.expiry_date).startOf("hour")

    // RENDER
    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Voucher Details
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        px: 2,
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                        }}
                    >
                        <TextField
                            variant="outlined"
                            label="Name"
                            margin="normal"
                            fullWidth
                            value={voucherData?.name}
                            size="small"
                            sx={{
                                mb: 1,
                            }}
                            inputProps={{ readOnly: true }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column",
                                    sm: "row",
                                },
                                gap: "0.5em",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <TextField
                                variant="outlined"
                                label="Code"
                                margin="normal"
                                fullWidth
                                value="*****"
                                size="small"
                                inputProps={{ readOnly: true }}
                            />
                            {copy ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.1em",
                                        color: "#57d246",
                                    }}
                                >
                                    <Typography
                                        textAlign="center"
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        Copied!
                                    </Typography>
                                    <CheckCircleOutline
                                        variant="success"
                                        sx={{ alignSelf: "center" }}
                                    />
                                </Box>
                            ) : (
                                <Box>
                                    <Tooltip title="Click to copy">
                                        <IconButton
                                            variant="outlined"
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    voucherData?.voucher_code
                                                )
                                                setCopy(true)
                                            }}
                                        >
                                            <ContentCopyOutlined
                                                sx={{ color: "primary.main" }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                        </Box>
                        <TextField
                            variant="outlined"
                            label="Amount"
                            margin="normal"
                            value={voucherData?.amount}
                            size="small"
                            sx={{
                                mb: 1,
                            }}
                            inputProps={{ readOnly: true }}
                        />
                        <TextField
                            variant="outlined"
                            label="User Limit"
                            margin="normal"
                            fullWidth
                            value={voucherData?.user_limit}
                            size="small"
                            sx={{
                                mb: 1,
                            }}
                            inputProps={{ readOnly: true }}
                        />
                        <TextField
                            variant="outlined"
                            label="Expire Date"
                            margin="normal"
                            fullWidth
                            size="small"
                            value={
                                expired
                                    ? `Expired ( ${DateTime.fromISO(
                                          voucherData?.expiry_date
                                      ).toFormat("ff")} )`
                                    : DateTime.fromISO(
                                          voucherData?.expiry_date
                                      ).toFormat("ff")
                            }
                            sx={{
                                mb: 1,
                            }}
                            inputProps={{ readOnly: true }}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mb: 1,
                    pr: 4,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        color: "primary.main",
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleClose}
                >
                    <Typography>Close</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default VoucherDetails
