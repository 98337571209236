import { useContext } from "react"
import { useInfiniteQuery } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"

const useReactQueryInfinitQuery = ({url, filter}) => {
    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)

    return useInfiniteQuery({
        queryKey: [companyId, url, filter],
        queryFn: ({ pageParam }) => {
            const { page = 0, perPage = 15 } = pageParam || {}
            return axiosPrivate
                .get(`corporate/${companyId}/${url}`, {
                    params: {
                        page: page,
                        per_page: perPage,
                        filter: JSON.stringify(filter)
                    },
                })
                .then((res) => res)
        },
        getNextPageParam: (lastPage) => {
            const {page: lastPageNo, total } = lastPage?.data?.meta_data || {}
            const hasNextPage =  total - (lastPageNo + 1) * 15 > 0
            
            return hasNextPage ? {
                page: lastPageNo + 1,
                perPage: 15,
            } : undefined
        },
    })
}

export default useReactQueryInfinitQuery