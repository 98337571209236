import { Button } from "@mui/material"
import { DateTime } from "luxon"
import MaterialReactTable from "material-react-table"
import React from "react"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useTableProps from "../../Hooks/useTableProps.js"
import useTableState from "../../Hooks/useTableState.js"

const topUps = [
    {
        accessorKey: "created_by",
        header: "Payment Initiator",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "amount",
        header: "Amount",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "created_at",
        header: "Payment Date",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
    },
    {
        accessorKey: "id",
        header: "Approver",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "bank_name",
        header: "Bank Name",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "status",
        header: "Status",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => (
            <Button
                sx={{
                    height: "2em",
                    backgroundColor: "lightgray",
                    borderRadius: "1em",
                }}
            >
                {cell.getValue()}
            </Button>
        ),
    },
    {
        accessorKey: "remark",
        header: "Remark",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "reference_number",
        header: "Reference Number",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "updated_at",
        header: "Updated at",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
    },
]

function TopUps() {
    const { md } = useBreakPoints()

    const { params, state, onChangeHandlers } = useTableState({
        columns: topUps,
    })

    const { data, isLoading, isFetching } = useFetchEntitiesPerPage({
        endPoint: "top_ups",
        ...params,
    })

    const { props } = useTableProps({
        columns: topUps,
        exportProps: {
            dataEndPoint: "users",
            model: params.filterModel,
        },
        state: {
            ...state,
            showProgressBars: isFetching,
            showSkeletons: isLoading,
        },
    })

    return (
        <MaterialReactTable
            data={data?.data?.data?.map((el) => el.corporate_top_up) || []}
            initialState={{
                columnPinning: {
                    right: [md ? "action" : null],
                },
            }}
            {...onChangeHandlers}
            {...props}
        />
    )
}

export default TopUps
