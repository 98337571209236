import React, { useEffect, useRef } from "react"
import { Box } from "@mui/material"
import maplibreGl from "maplibre-gl"
import { toGeoJSON } from "@mapbox/polyline"
import "maplibre-gl/dist/maplibre-gl.css"

function startMarker() {
    const ele = document.createElement("div")

    ele.style.height = "25px"
    ele.style.width = "25px"
    ele.style.borderRadius = "12.5px"
    ele.style.backgroundColor = "white"
    ele.style.display = "flex"
    ele.style.alignItems = "center"
    ele.style.justifyContent = "center"
    ele.style.border = "2px solid #03a5c6"

    const innerEle = document.createElement("div")
    innerEle.style.height = "12px"
    innerEle.style.width = "12px"
    innerEle.style.borderRadius = "6px"
    innerEle.style.backgroundColor = "white"
    innerEle.style.border = "4px solid #03a5c6"

    ele.appendChild(innerEle)

    return ele
}

function MapLibre({ path }) {
    const ref = useRef(null)

    const decoded = toGeoJSON(path)

    useEffect(() => {
        const map = new maplibreGl.Map({
            container: ref.current,
            style: "https://maps.rideplus.co/osmStyle.json", // stylesheet location
            center: !path ? [38.78538, 8.99856] : decoded?.coordinates[0], // starting position [lng, lat]
            zoom: 10, // starting zoom
            pitch: 60,
            // bearing: 60,
        })

        map.addControl(new maplibreGl.NavigationControl())
        map.addControl(new maplibreGl.FullscreenControl())

        const coords = decoded?.coordinates

        new maplibreGl.Marker({ element: startMarker() })
            .setLngLat(coords[0])
            .addTo(map)
        new maplibreGl.Marker({})
            .setLngLat(coords[coords.length - 1])
            .addTo(map)

        if (path) {
            map.on("load", () => {
                map.addSource("polyline", {
                    type: "geojson",
                    data: {
                        type: "LineString",
                        coordinates: decoded?.coordinates,
                    },
                })

                map.addLayer({
                    id: "trip-path",
                    type: "line",
                    source: "polyline",
                    paint: {
                        "line-color": "green",
                        "line-width": 3,
                        "line-opacity": 1,
                    },
                })

                const bounds = decoded?.coordinates.reduce(
                    (bound, coord) => bound.extend(coord),
                    new maplibreGl.LngLatBounds(
                        decoded?.coordinates[0],
                        decoded?.coordinates[0]
                    )
                )

                map.fitBounds(bounds, {
                    padding: 50,
                })
            })
        }
        if (!path) {
            new maplibreGl.Popup({ closeOnClick: false })
                .setLngLat([38.78538, 8.99856])
                .setHTML(
                    '<p style="font-weight: bold" >Trip Info Unavailable!<br />No recorded Info for this trip</p>'
                )
                .addTo(map)
        }
    }, [decoded, path])

    return (
        <Box
            ref={ref}
            sx={{
                width: "100%",
                height: "100%",
            }}
        />
    )
}

export default MapLibre
