import {
    Autocomplete,
    Box,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import MaterialReactTable from "material-react-table"
import { DateTime } from "luxon"
import {
    Edit,
    HighlightOff,
    PlaylistRemove,
    TaskAlt,
    Visibility,
} from "@mui/icons-material"
import React, { useMemo, useCallback, useState } from "react"
import { Link } from "react-router-dom"
import useTableProps from "../../Hooks/useTableProps.js"
import useTableState from "../../Hooks/useTableState.js"
import StatusButton from "../../Component/statusButton.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import customeFilter from "../../Component/customFilterPanel.jsx"
import AddSystemUser from "../../Component/addSystemUser.jsx"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import UpdateSystemUser from "../../Component/updateSystemUser.jsx"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"

const userFields = [
    {
        accessorKey: "first_name",
        header: "Name",
        size: 200,
        Cell: ({ row }) =>
            `${row.original.first_name} ${row.original.middle_name}`,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorFn: (row) => row?.roles,
        id: "role_name",
        header: "Role",
        size: 300,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "email",
        header: "Email",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "created_at",
        header: "Created At",
        size: 200,
        filterVariant: "date",
        Cell: ({ cell }) =>
            `${DateTime.fromISO(cell.getValue()).toFormat("ff")}`,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    // {
    //     accessorKey: "status",
    //     header: "Status",
    //     size: 200,
    //     filterVariant: "select",
    //     filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
    //     renderColumnFilterModeMenuItems: customeFilter,
    // },
    {
        accessorKey: "actions",
        header: " ",
        enableColumnFilter: false,
        enableSorting: false,
        size: 200,
    },
]

function SystemUsers() {
    const {
        systemUsersAdd,
        systemUsersList,
        systemUsersPage,
        systemUsersRevokeRole,
    } = useUserPermissions()
    const { md } = useBreakPoints()

    const { onChangeHandlers, state, params } = useTableState({
        columns: userFields,
    })
    const [user, setUser] = useState(null)
    const [assign, setAssign] = useState(null)

    const { data, isLoading, isFetching } = useFetchEntitiesPerPage(
        {
            endPoint: "system/users",
            ...params,
        },
        { enabled: systemUsersList }
    )

    const [open, setOpen] = useState(false)

    const statusButton = useCallback(() => <StatusButton status="ACTIVE" />, [])

    const roleColumn = useCallback(
        ({ cell }) => (
            <Autocomplete
                readOnly
                multiple
                value={cell.getValue()}
                options={[]}
                getOptionLabel={(opt) => opt?.role_name}
                renderTags={(value) =>
                    value?.map((el) => (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                overflow: "hidden",
                                m: ".05em",
                                backgroundColor:
                                    el?.status === "ACTIVE"
                                        ? "success.main"
                                        : "error.main",
                                color: "white",
                                borderRadius: "20px",
                                pr: "1em",
                            }}
                        >
                            <IconButton
                                size="small"
                                sx={{
                                    color: "white",
                                }}
                            >
                                {el?.status === "ACTIVE" ? (
                                    <TaskAlt fontSize=".1em" />
                                ) : (
                                    <HighlightOff fontSize=".1em" />
                                )}
                            </IconButton>
                            <Typography variant="subtitle2" noWrap>
                                {el?.role_name}
                            </Typography>
                        </Box>
                    ))
                }
                renderInput={(paras) => <TextField label="Roles" {...paras} />}
                sx={{
                    ".MuiAutocomplete-endAdornment": {
                        display: "none",
                    },
                    ".MuiInputBase-root > input": {
                        display: "none",
                    },
                }}
            />
        ),
        []
    )

    const actionColumn = useCallback(
        ({ row }) => (
            <Box>
                <Tooltip title="revoke user role">
                    <IconButton
                        disabled={!systemUsersRevokeRole}
                        onClick={() => setUser(row.original || null)}
                        sx={{ color: "primary.main" }}
                    >
                        <PlaylistRemove />
                    </IconButton>
                </Tooltip>
                <Tooltip title="assign new role">
                    <IconButton
                        disabled={!systemUsersAdd}
                        onClick={() => {
                            setUser(row.original || null)
                            setAssign(row.original || null)
                        }}
                        sx={{ color: "primary.main" }}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title="view roles">
                    <Link to={`${row.original.id}/view`} state={row.original}>
                        <IconButton
                            // disabled={!systemUsersView}
                            sx={{ color: "primary.main" }}
                        >
                            <Visibility />
                        </IconButton>
                    </Link>
                </Tooltip>
            </Box>
        ),
        [systemUsersRevokeRole, systemUsersAdd]
    )

    const dateFilter = useCallback(
        (props) => <CustomeDateFilter {...props} />,
        []
    )

    // ADD RENDER CELL METHOD TO COL DEFS FOR STATUS
    const colDefs = useMemo(
        () =>
            userFields.map((el) => {
                if (el.accessorKey === "created_at")
                    return {
                        ...el,
                        Filter: dateFilter,
                    }
                if (el.accessorKey === "status") {
                    return {
                        ...el,
                        Cell: statusButton,
                    }
                }
                if (el.id === "role_name") {
                    return {
                        ...el,
                        Cell: roleColumn,
                    }
                }
                if (el.accessorKey === "actions") {
                    return {
                        ...el,
                        Cell: actionColumn,
                    }
                }
                return el
            }),
        [statusButton, roleColumn, actionColumn, dateFilter]
    )

    const { props } = useTableProps({
        columns: colDefs,
        actionButtonProps: systemUsersAdd && {
            actionName: "Add Users",
            onClick: () => setOpen(true),
        },
        exportProps: {
            dataEndPoint: "system/users",
            model: params.filterModel,
        },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
    })

    if (!systemUsersPage) return <RestrictedAccess />

    return (
        <Box>
            {/* <ViewSystemUser view={view} setView={setView} /> */}
            {systemUsersRevokeRole && (
                <UpdateSystemUser
                    user={user}
                    setUser={setUser}
                    assign={assign}
                    setAssign={setAssign}
                />
            )}
            {systemUsersAdd && <AddSystemUser open={open} setOpen={setOpen} />}
            <Box sx={{ backgroundColor: "white" }}>
                <MaterialReactTable
                    data={data?.data?.data || []}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    initialState={{
                        columnPinning: {
                            left: [md ? "name" : null],
                            right: [md ? "actions" : null],
                        },
                    }}
                    {...props}
                    {...onChangeHandlers}
                />
            </Box>
        </Box>
    )
}

export default SystemUsers
