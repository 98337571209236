import React, { useEffect, useState } from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material"

function ConfirmationDialog({ message, onConfirmation }) {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (message) setOpen(true)
    }, [message])

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle
                sx={{
                    backgroundColor: "#fafafa",
                }}
            >
                {message?.header}
            </DialogTitle>
            <DialogContent>{message?.body}</DialogContent>
            <DialogActions
                sx={{
                    backgroundColor: "#fafafa",
                }}
            >
                <Button
                    variant="contained"
                    color="common"
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onConfirmation()
                        setOpen(false)
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog
