import { ExportToCsv } from "export-to-csv"
import { useSnackbar } from "notistack"
import { useContext } from "react"
import { useQuery } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"

// Fetch/Get All Entities List Hook
const useExport = (endPoint, model, processor) => {
    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()

    // THE OPTIONAL ARGUMENT, CALLBACK, `PROCESSOR` PREPARES THE DATA FOR EXPORT

    const csvOptions = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: columns.map((c) => c.header),
    }

    const csvExporter = new ExportToCsv(csvOptions)

    return useQuery(
        [endPoint, model],
        () =>
            axiosPrivate
                .get(`/corporate/${companyId}/${endPoint}`, {
                    params: {
                        page: 0,
                        per_page: -1,
                        filter: model ? JSON.stringify(model) : undefined,
                        link_operator: "and",
                    },
                })
                .then((response) => response),
        {
            enabled: false,
            onSuccess: (data) => {
                if (processor) {
                    const prepared = processor(data?.data?.data)
                    csvExporter.generateCsv(prepared)
                } else {
                    csvExporter.generateCsv(data?.data?.data)
                }
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )
}

export default useExport
