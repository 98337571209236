import React, { useContext, useMemo, useState } from "react"
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    TextField,
    Typography,
} from "@mui/material"
import { Cancel } from "@mui/icons-material"
import { DataGrid } from "@mui/x-data-grid"
import { useQuery } from "react-query"
import { find, reject } from "lodash"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"
import AuthContext from "../Context/auth_provider.jsx"
import useInviteUsers from "../Hooks/useInviteUsers.js"
import useAssignPrograms from "../Hooks/useAssignPrograms.js"
import LoadingPage from "./loadingPage.jsx"
import isPhone from "../Utils/isPhone.js"
import { RP_ASSETS_URL, SSO_API_BASE_URL } from "../Utils/config.js"
import useUserPermissions from "../Hooks/useUserPermissions.js"

const people = [
    {
        field: "profilePic",
        headerName: " ",
        headerClassName: "white-header",
        flex: 0.5,
        sortable: false,
    },
    {
        field: "name",
        headerName: "Name",
        headerClassName: "white-header",
        flex: 3,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "phone",
        headerName: "Phone",
        headerClassName: "white-header",
        flex: 2,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "programs",
        headerName: "Programs",
        headerClassName: "white-header",
        flex: 4,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "guest",
        headerName: "Guest",
        headerClassName: "white-header",
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
            const str = params.row.guest?.toLowerCase()
            return str === "yes" ? `Yes` : "No"
        },
    },
    {
        field: "action",
        headerName: " ",
        headerClassName: "white-header",
        flex: 0.5,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
]

function AddUsers({ file, setFile, close }) {
    const { usersAssign, usersInvite } = useUserPermissions()
    const { companyId } = useContext(AuthContext)
    const axiosPrivate = useAxiosPrivate()

    const {
        inviteUser,
        clearInvite,
        requestInvitation,
        isLoading: inviting,
    } = useInviteUsers()
    const {
        assignUser,
        clearAssign,
        requestAssign,
        isLoading: assigning,
    } = useAssignPrograms()
    const [invite, setInvite] = useState(false)

    const {
        data: users,
        isError,
        error,
        isLoading,
    } = useQuery(["ssoUsers"], () =>
        axiosPrivate
            .post(`corporate/${companyId}/users/phone`, {
                phones: file
                    .filter((ele) => !!ele?.phone)
                    .map((el) => el.phone),
            })
            .then((res) => res.data)
    )

    // AGGREGATE USERS DATA FROM BOTH SSO AND CSV
    const aggregated = useMemo(
        () =>
            file?.map((ele) => {
                const registeredUser = find(users?.data, (user) =>
                    isPhone(user.phone, ele.phone)
                )

                if (registeredUser) {
                    // ADD USER TO BULK ASSIGN FORM
                    assignUser({
                        userId: registeredUser.id,
                        programs: ele.programs,
                        guest: ele.guest === "Yes",
                    })
                    return {
                        ...ele,
                        name: `${registeredUser.first_name} ${registeredUser.last_name}`,
                    }
                }
                // ADD USER TO INVITATION FORM
                inviteUser({
                    phone: ele.phone,
                    programs: ele.programs,
                    guest: ele.guest === "Yes",
                })
                return ele
            }),
        [file, users, assignUser, inviteUser]
    )

    const handleCancel = (data) => {
        clearAssign()
        clearInvite()
        const removed = reject(file, (ele) => ele.phone === data.phone)
        setFile(removed)
    }

    const colFields = people.map((ele) => {
        if (ele.field === "programs") {
            return {
                ...ele,
                width: 300,
                renderCell: (params) => (
                    <Autocomplete
                        readOnly
                        multiple
                        fullWidth
                        value={params.row?.programs
                            ?.split(",")
                            .map((el) => el.trim())}
                        options={[]}
                        renderInput={(paras) => <TextField {...paras} />}
                        sx={{
                            "& .MuiChip-label": {
                                color: "secondary.main",
                                fontWeight: "bold",
                                letterSpacing: ".1em",
                            },
                            "& .MuiChip-root": {
                                backgroundColor: "white",
                            },
                            "& .MuiSvgIcon-root": {
                                display: "none",
                            },
                        }}
                    />
                ),
            }
        }
        if (ele.field === "action") {
            return {
                ...ele,
                renderCell: (params) => (
                    <IconButton onClick={() => handleCancel(params?.row)}>
                        <Cancel />
                    </IconButton>
                ),
            }
        }
        if (ele.field === "profilePic") {
            return {
                ...ele,
                renderCell: (params) => (
                    <Avatar
                        sx={{
                            height: "100%",
                            maxHeight: "40px",
                            borderRadius: "50%",
                            objectFit: "contain",
                        }}
                        src={
                            `${SSO_API_BASE_URL}/v1/${RP_ASSETS_URL}/${
                                find(users?.data, (el) =>
                                    isPhone(el.phone, params.row.phone)
                                )?.profile_picture
                            }` || ""
                        }
                    />
                ),
            }
        }
        return ele
    })

    if (isLoading) return <LoadingPage message="...fetching user data" />
    if (isError) {
        return (
            <Box>
                <Typography>
                    {error.response?.data?.error?.field_error?.map(
                        (err) =>
                            `${err.name} at line ${
                                err.description
                                    ? Number(err.description.split(":")[0]) + 1
                                    : null
                            } is invalid`
                    )}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => close()}
                        sx={{ mt: "1em" }}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                marginTop: file ? "1em" : null,
                "& .white-header": {
                    backgroundColor: "white",
                },
            }}
        >
            <Box sx={{ height: "250px" }}>
                <DataGrid
                    getRowHeight={() => "auto"}
                    getRowId={(params) => params.phone}
                    hideFooter
                    disableSelectionOnClick
                    disableColumnMenu
                    columns={colFields}
                    rows={aggregated.filter((el) => el.phone) || []}
                    sx={{
                        backgroundColor: "common.main",
                    }}
                />
            </Box>
            {usersInvite ? (
                <>
                    {!usersAssign && (
                        <Typography variant="subtitle2" sx={{ pt: ".5em" }}>
                            You Don`t have permission to assign programs to
                            Users, but you can invite new users
                        </Typography>
                    )}
                    <FormControlLabel
                        checked={invite}
                        onChange={(e) => {
                            setInvite(e.target.checked)
                        }}
                        control={<Checkbox />}
                        label="Invite the people who are not found on the system"
                        sx={{
                            mt: "1em",
                            pl: "2em",
                        }}
                    />
                </>
            ) : (
                <>
                    <Typography variant="subtitle2" sx={{ pt: ".5em" }}>
                        Only the users found on ridePlus will be added to their
                        respective programs
                    </Typography>
                    <Typography variant="subtitle2">
                        You can request your system admin for a permission to
                        invite the rest, rather than discard them.
                    </Typography>
                </>
            )}
            <Divider sx={{ my: "1em" }} />
            <Box
                sx={{
                    display: "flex",
                    gap: "1em",
                    pr: "2em",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    variant="contained"
                    color="common"
                    onClick={() => close()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    startIcon={
                        assigning || inviting ? (
                            <CircularProgress
                                size="1em"
                                sx={{ color: "white" }}
                            />
                        ) : null
                    }
                    onClick={() => {
                        if (invite && usersInvite) {
                            requestInvitation(() => close())
                        }
                        return usersAssign && requestAssign(() => close())
                    }}
                >
                    Confirm
                </Button>
            </Box>
        </Box>
    )
}

export default AddUsers
