import React from "react"
import QRCode from "qrcode.react"
import IconButton from "@mui/material/IconButton"
import { QrCode2Outlined } from "@mui/icons-material"

function DownloadQRCode({ qrCodeText }) {
    // download QR code
    const downloadQR = () => {
        const qrCodeURL = document
            .getElementById(qrCodeText)
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        const aEl = document.createElement("a")
        aEl.href = qrCodeURL
        aEl.download = "Voucher_QR_Code.png"
        document.body.appendChild(aEl)
        aEl.click()
        document.body.removeChild(aEl)
    }

    return (
        <>
            <QRCode
                id={qrCodeText}
                size={150}
                value={qrCodeText}
                style={{ display: "none" }}
                fgColor="#FFFFFF"
                bgColor="#000000"
            />
            <IconButton onClick={downloadQR} sx={{ color: "primary.main" }}>
                <QrCode2Outlined />
            </IconButton>
        </>
    )
}

export default DownloadQRCode
