import { useContext } from "react";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";

import useAxiosPrivate from "./useAxiosPrivate.js";
import AuthContext from "../Context/auth_provider.jsx";

const useDeleteEntity = (path) => {
  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const { companyId } = useContext(AuthContext)

  return useMutation(() => axiosPrivate.delete(`/corporate/${companyId}/${path}`)
    ,
    {
      onSuccess: () =>
        enqueueSnackbar(`Succesfully deleted.`, {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 2000,
        }),
      onError: (error) =>
        enqueueSnackbar(
          error?.response?.data?.message ||
          error?.message ||
          "Network Error!",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000,
          }
        )
    },
  )
};

export default useDeleteEntity;
