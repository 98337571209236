import { Box, Typography } from "@mui/material"
import React from "react"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null }
        this.prevPath = null
    }

    static getDerivedStateFromError(error) {
        return { error: true, errorInfo: error }
    }

    componentDidMount() {
        this.prevPath = window.location.pathname
    }

    componentDidUpdate() {
        if (window.location.pathname !== this.prevPath)
            this.setState({ error: null })
        this.prevPath = window.location.pathname
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error("Error boundary Message:  ", error, errorInfo)
    }

    render() {
        const { error } = this.state
        const { children } = this.props

        if (error) {
            // You can render any custom fallback UI
            return (
                <Box sx={{ p: "1em" }}>
                    <Typography>Something went wrong</Typography>
                </Box>
            )
        }

        return children
    }
}

export default ErrorBoundary
