import React, { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import useCreateEntity from "../../Hooks/useCreateEntity.js"
import useFetchEntities from "../../Hooks/useFetchEntity.js"

// Create/Add Roles Component
function CreateRoles({ handleModalClose, open, refetch }) {
    // USESTATE
    const [selectedPermissions, setSelecetdPermissions] = useState([])
    const permissionRef = useRef()

    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
        setValue,
        watch,
    } = useForm({
        mode: "onChange",
    })

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERIES $ MUTATIONS
    const { data: permissions, isLoading: permissionsIsLoading } =
        useFetchEntities(`permissions`)
    const { mutate, isLoading, data: roles, error } = useCreateEntity(`roles`)

    // DATA STRUCTURE for Permissions Category By Name
    const permissionCategories = permissions?.data?.data?.map(
        (element) => element.statement.resource.split(":")[1]
    )
    const uniquePermissionCategories = [...new Set(permissionCategories)]

    permissionRef.current = watch("permissions_id")

    // HELPERS & EVENT HANDLERS
    const onSubmit = (formData) => {
        const roleName = formData.name.trim()
        const toBeSent = { ...formData, name: roleName }
        mutate(toBeSent)
    }
    const handleSelect = (checked, category) => {
        const selectedPerm = permissions?.data?.data?.filter(
            (element) => element.statement.resource.split(":")[1] === category
        )
        const perm = selectedPerm?.map((item) => item.id)
        const unchecked = selectedPermissions?.filter(
            (value) => !perm.includes(value)
        )
        const permIntersect = Array.from(
            new Set([...selectedPermissions, ...perm])
        )
        if (checked) setSelecetdPermissions(permIntersect)
        if (!checked) setSelecetdPermissions(unchecked)
    }
    const handleItemSelect = (checked, permissionId) => {
        const unchecked = selectedPermissions?.filter(
            (value) => value !== permissionId
        )

        if (checked)
            setSelecetdPermissions(
                Array.from(new Set([...selectedPermissions, permissionId]))
            )
        if (!checked) setSelecetdPermissions(unchecked)
    }
    const viewSelectedCategory = (category) => {
        const categoryItems = permissions?.data?.data
            ?.filter(
                (permission) =>
                    permission?.statement?.resource.split(":")[1] === category
            )
            .map((item) => item.id)
        const selectedCategoryItems = permissions?.data?.data?.filter(
            (permission) => selectedPermissions?.includes(permission.id)
        )
        const selected = selectedCategoryItems
            ?.filter(
                (permission) =>
                    permission?.statement?.resource.split(":")[1] === category
            )
            .map((item) => item.id)

        return (
            selected?.length === categoryItems.length &&
            selected.every((value, index) => value === categoryItems[index])
        )
    }

    // USEFFECTS
    useEffect(() => {
        setValue("permissions_id", selectedPermissions)
    }, [selectedPermissions, setValue])

    useEffect(() => {
        if (roles) {
            enqueueSnackbar(`Succesfully created a new role.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            handleModalClose()
            refetch()
        }
    }, [roles, enqueueSnackbar, handleModalClose, refetch])

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, error])

    // RENDER
    return (
        !permissionsIsLoading && (
            <Dialog
                maxWidth="md"
                open={open}
                onClose={handleModalClose}
                scroll="paper"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            Add Role
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            mx: 2,
                            mt: 2,
                        }}
                    >
                        <Box>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Name is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Name"
                                        autoComplete="name"
                                        fullWidth
                                        error={!!errors?.name}
                                        helperText={
                                            errors?.name
                                                ? errors.name.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mb: 1,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                            <Divider />
                        </Box>
                        <Box
                            sx={{
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}
                        >
                            <FormControl>
                                {uniquePermissionCategories.map((category) => (
                                    <FormGroup
                                        key={
                                            category === undefined
                                                ? "Uncategorised"
                                                : category
                                        }
                                        sx={{
                                            mt: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Checkbox
                                                checked={viewSelectedCategory(
                                                    category
                                                )}
                                                onChange={(e) =>
                                                    handleSelect(
                                                        e.target.checked,
                                                        category
                                                    )
                                                }
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: "primary.main",
                                                    },
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {category === undefined
                                                    ? "UNCATEGORISED"
                                                    : category.toUpperCase()}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "inline-block",
                                                ml: 4,
                                            }}
                                        >
                                            {permissions?.data?.data?.map(
                                                (item) =>
                                                    item.statement.resource.split(
                                                        ":"
                                                    )[1] === category && (
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                visibility:
                                                                    "visible",
                                                                mt: 2,
                                                                mx: 1,
                                                                p: 0.7,
                                                                bgcolor:
                                                                    "grey.100",
                                                                color: "grey.800",
                                                                border: "1px solid",
                                                                borderColor:
                                                                    "grey.300",
                                                                borderRadius: 2,
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    "700",
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    item.description
                                                                }
                                                                arrow
                                                            >
                                                                <FormControlLabel
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    value={
                                                                        item.id
                                                                    }
                                                                    control={
                                                                        <Checkbox
                                                                            checked={permissionRef.current?.includes(
                                                                                item.id
                                                                            )}
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleItemSelect(
                                                                                    e
                                                                                        .target
                                                                                        .checked,
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                "&.Mui-checked":
                                                                                    {
                                                                                        color: "primary.main",
                                                                                    },
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={
                                                                        item.name
                                                                    }
                                                                    name={item.id}
                                                                    {...register(
                                                                        "permissions_id"
                                                                    )}
                                                                />
                                                            </Tooltip>
                                                        </Box>
                                                    )
                                            )}
                                        </Box>
                                    </FormGroup>
                                ))}
                            </FormControl>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        mb: 1,
                        pr: 4,
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            color: "primary.main",
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        onClick={handleModalClose}
                    >
                        <Typography>Cancel</Typography>
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            bgcolor: "primary.main",
                            ":hover": { bgcolor: "primary.main" },
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                    >
                        <Typography>Add</Typography>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "common.main",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    )
}
export default CreateRoles
