import React, { useContext, useState } from "react"
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from "@mui/material"
import { useMutation } from "react-query"
import { useSnackbar } from "notistack"
import AuthContext from "../../Context/auth_provider.jsx"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import { CompanyInfo } from "../SystemUsers/companyList.jsx"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import ActivityLog from "../../Component/activityLog.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"

function Config() {
    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)

    const { activitiesList } = useUserPermissions()

    const { refetch, selectedCompany } = useContext(CompanyInfo)

    const {
        allow_tip: allowTip,
        max_tip: maxTip,
        min_tip: minTip,
        mid_tip: midTip,
    } = selectedCompany || {}

    const { xs, md } = useBreakPoints()

    const [min, setMin] = useState(() => (minTip > 0 ? minTip : null))
    const [mid, setMid] = useState(() => (midTip > 0 ? midTip : null))
    const [max, setMax] = useState(() => (maxTip > 0 ? maxTip : null))

    const invalidMin = min >= mid || min <= 0
    const invalidMid = min >= mid || mid >= max || mid <= 0
    const invalidMax = max > 100 || max <= mid || max <= 0

    const { enqueueSnackbar } = useSnackbar()

    const { mutate, isLoading } = useMutation(
        (dat) => axiosPrivate.patch(`/corporate/${companyId}/tips`, dat),
        {
            onSuccess: () => {
                refetch()
                enqueueSnackbar("Updated successfully", { variant: "success" })
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )

    const handleCheckbox = (e) => {
        const { checked } = e.currentTarget
        mutate(
            {
                allow_tip: checked ? "ACTIVE" : "INACTIVE",
            },
            {
                onSuccess: () => {
                    refetch()
                },
                onError: (error) => {
                    const fieldError =
                        error.response?.data?.error?.field_error?.[0]
                            ?.description
                    const errorMessage = error.response?.data?.error?.message
                    enqueueSnackbar(
                        fieldError || errorMessage || "Request Failed",
                        { variant: "error" }
                    )
                },
            }
        )
    }

    return (
        <Box
            sx={{
                ml: xs && !md ? "1em" : "4em",
                mr: "1em",
                mt: "3em",
                pb: "2em",
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                gap: "2em",
            }}
        >
            <Box>
                <FormControlLabel
                    disabled={isLoading}
                    checked={allowTip === "ACTIVE"}
                    onChange={handleCheckbox}
                    label="Enable Tipping"
                    control={<Checkbox />}
                />
                {allowTip === "ACTIVE" && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1em",
                            mt: "1.7em",
                            ml: xs && !md ? undefined : "2em",
                        }}
                    >
                        <Grid
                            container
                            direction={xs && !md ? "column" : undefined}
                        >
                            <Grid item xs={3}>
                                <Typography>Low</Typography>{" "}
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    value={min || ""}
                                    label={invalidMin && "invalid value"}
                                    error={invalidMin}
                                    type="number"
                                    onChange={(e) =>
                                        setMin(Number(e.target.value))
                                    }
                                    InputProps={{
                                        inputProps: {
                                            max: 100,
                                            sx: {
                                                backgroundColor: "white",
                                                mr: ".5em",
                                            },
                                        },
                                        sx: {
                                            "&.MuiInputBase-root": {
                                                pr: "0.5em",
                                                backgroundColor: "#dfdfdf",
                                            },
                                        },
                                        endAdornment: (
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                %
                                            </Typography>
                                        ),
                                    }}
                                    helperText={
                                        <Box>
                                            <Typography
                                                varaint="subtitle2"
                                                sx={{
                                                    fontWeight: "light",
                                                    fontSize: "1em",
                                                }}
                                            >
                                                Should be less than medium value
                                                and greater than zero
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction={xs && !md ? "column" : undefined}
                        >
                            <Grid item xs={3}>
                                <Typography>Medium</Typography>{" "}
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    value={mid || ""}
                                    label={invalidMid && "invalid value"}
                                    error={invalidMid}
                                    onChange={(e) =>
                                        setMid(Number(e.target.value))
                                    }
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            max: 100,
                                            sx: {
                                                backgroundColor: "white",
                                                mr: ".5em",
                                            },
                                        },
                                        sx: {
                                            "&.MuiInputBase-root": {
                                                pr: "0.5em",
                                                backgroundColor: "#dfdfdf",
                                            },
                                        },
                                        endAdornment: (
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                %
                                            </Typography>
                                        ),
                                    }}
                                    helperText={
                                        <Box>
                                            <Typography
                                                varaint="subtitle2"
                                                sx={{
                                                    fontWeight: "light",
                                                    fontSize: "1em",
                                                }}
                                            >
                                                Should be less than Maximum and
                                                greater than minimum value
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction={xs && !md ? "column" : undefined}
                        >
                            <Grid item xs={3}>
                                <Typography>High</Typography>{" "}
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    value={max || ""}
                                    type="number"
                                    label={invalidMax && "invalid value"}
                                    error={invalidMax}
                                    onChange={(e) =>
                                        setMax(Number(e.target.value))
                                    }
                                    InputProps={{
                                        inputProps: {
                                            max: 100,
                                            sx: {
                                                backgroundColor: "white",
                                                mr: ".5em",
                                            },
                                        },
                                        sx: {
                                            "&.MuiInputBase-root": {
                                                pr: "0.5em",
                                                backgroundColor: "#dfdfdf",
                                            },
                                        },
                                        endAdornment: (
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                %
                                            </Typography>
                                        ),
                                    }}
                                    helperText={
                                        <Box>
                                            <Typography
                                                varaint="subtitle2"
                                                sx={{
                                                    fontWeight: "light",
                                                    fontSize: "1em",
                                                }}
                                            >
                                                Should be less than 100 and
                                                greater than medium value
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Box>
                            <Button
                                disabled={
                                    invalidMin || invalidMax || invalidMid
                                }
                                onClick={() =>
                                    mutate({
                                        max_tip: max,
                                        min_tip: min,
                                        mid_tip: mid,
                                        allow_tip: "ACTIVE",
                                    })
                                }
                                startIcon={
                                    isLoading ? (
                                        <CircularProgress
                                            size="1em"
                                            sx={{ color: "white" }}
                                        />
                                    ) : null
                                }
                                variant="contained"
                                sx={{
                                    mt: "1em",
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
            {activitiesList && (
                <Box sx={{ flex: 1 }}>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", pb: "0px", pl: "16px" }}
                    >
                        Activity Log
                    </Typography>
                    <Divider variant="middle" sx={{ pb: "1em" }} />
                    <ActivityLog
                        url="activities"
                        filter={[
                            {
                                column_field: "url",
                                operator_value: "contains",
                                value: `v2/corporate/${companyId}/tips`,
                            },
                        ]}
                    />
                </Box>
            )}
        </Box>
    )
}

export default Config
