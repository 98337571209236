import { find } from "lodash"

const filterModel = (filterFns, columnFields, columns) =>
    columnFields.map((item) => {
        const operatorValue = filterFns[item.id]
        const colType = find(
            columns,
            (el) => el.accessorKey === item.id || el.id === item.id
        )?.filterVariant

        const isPeoplePrograms = item.id === "programs"

        if (colType === "json")
            return {
                column_field: isPeoplePrograms ? "programs" : "detail",
                operator_value:
                    operatorValue === "contains" ? "@>" : operatorValue,
                value:
                    operatorValue === "empty" || operatorValue === "notEmpty"
                        ? ""
                        : `{"${isPeoplePrograms ? "name" : item.id}":"${item.value
                        }"}`,
            }
        return {
            column_field: item.id,
            operator_value: operatorValue === "inBetween" ? "between" : operatorValue,
            value:
                operatorValue === "empty" || operatorValue === "notEmpty"
                    ? ""
                    : item.value,
        }
    })

export default filterModel
