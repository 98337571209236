import React, { useContext, useEffect, useRef, useState } from "react"
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material"
import { useMutation, useQueryClient } from "react-query"
import { useSnackbar } from "notistack"
import { find } from "lodash"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"
import useBreakPoints from "../Hooks/useBreakPoints.js"
import useFetchEntities from "../Hooks/useFetchEntity.js"
import { SSO_VERSION, SSO_BASE_URL, SSO_ASSETS_URL } from "../Utils/config.js"

function User({ name, phone, src }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "common.main",
                py: "1em",
                px: "1em",
            }}
        >
            <Avatar
                style={{
                    height: "3em",
                    width: "3em",
                    borderRadius: "50%",
                    objectFit: "contain",
                }}
                src={src || " "}
            />
            <Typography
                variant="body1"
                sx={{ fontSize: "1.2em", textAlign: "center" }}
            >
                {name}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "1.2em" }}>
                +{phone}
            </Typography>
        </Box>
    )
}

function UpdateSystemUser({ user, setUser, assign, setAssign }) {
    const {
        first_name: fname,
        last_name: lname,
        middle_name: mname,
        phone,
        id,
        profile_picture: src,
        roles: userRoles,
    } = user || {}

    const { xs, md } = useBreakPoints()

    const queryClient = useQueryClient()

    const [role, setRole] = useState(null)

    // THIS STATE IS FOR ROLE ASSSIGNMENT WITH THE UPDATE COMPONENT
    const [selectedRole, setSelectedRole] = useState(null)
    const ref = useRef()

    const { data } = useFetchEntities("roles")

    // THIS IS TO INITIALIZE THE ROLE SELECTION WITH THE FIRST ROLE
    useEffect(() => {
        const firstRole = userRoles?.[0]
        if (!role && firstRole) setRole(firstRole)
    }, [userRoles, role])

    const { enqueueSnackbar } = useSnackbar()
    const { companyId } = useContext(AuthContext)
    const axiosPrivate = useAxiosPrivate()

    const { mutate: revokeRole, isLoading: updatingRole } = useMutation(
        (body) =>
            axiosPrivate.patch(`corporate/${companyId}/users/${id}/roles`, {
                role_id: body.id,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [companyId, "system/users"],
                })
                enqueueSnackbar("Role Removed Successfully", {
                    variant: "success",
                })
                setRole(null)
                setSelectedRole(null)
                setAssign(null)
                setUser(null)
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )

    const { mutate: assignRole, isLoading: assigningRole } = useMutation(
        (body) =>
            axiosPrivate.post(`corporate/${companyId}/users/${id}/roles`, {
                role: body.id,
            }),
        {
            onSuccess: () => {
                enqueueSnackbar("Role Assigned Successfully", {
                    variant: "success",
                })
                queryClient.invalidateQueries({
                    queryKey: [companyId, "system/users"],
                })
                setRole(null)
                setSelectedRole(null)
                setAssign(null)
                setUser(null)
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )

    return (
        <Dialog
            fullScreen={xs && !md}
            maxWidth="md"
            PaperProps={{
                sx: {
                    minWidth: md && "70ch",
                },
            }}
            open={!!user}
            onClose={() => {
                setUser(null)
                setRole(null)
                setSelectedRole(null)
                setAssign(null)
            }}
        >
            <DialogTitle
                sx={{
                    textAlign: "center",
                    backgroundColor: "#fafafa",
                    mb: "1em",
                    fontWeight: 550,
                    letterSpacing: ".01em",
                    wordSpacing: ".2em",
                }}
            >
                {assign ? "Assign New Role" : `Revoke User Role`}
            </DialogTitle>
            <DialogContent
                sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
                <User
                    name={`${fname} ${mname} ${lname}`}
                    phone={phone}
                    src={`${SSO_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${src}`}
                />
                <Autocomplete
                    value={!assign ? role : selectedRole}
                    options={!assign ? userRoles || [] : data?.data?.data || []}
                    getOptionDisabled={(option) =>
                        assign
                            ? Boolean(
                                  find(userRoles, (el) => el?.id === option?.id)
                              )
                            : false
                    }
                    getOptionLabel={(option) =>
                        !assign ? option?.role_name || "" : option?.name || ""
                    }
                    onChange={(e, newVal) =>
                        assign ? setSelectedRole(newVal) : setRole(newVal)
                    }
                    renderInput={(params) => (
                        <TextField
                            inputRef={ref}
                            label="choose role"
                            {...params}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions
                sx={{
                    backgroundColor: "#fafafa",
                    pr: md ? "1.55em" : undefined,
                }}
            >
                <Button
                    variant="contained"
                    color="common"
                    onClick={() => {
                        setUser(null)
                        setRole(null)
                        setSelectedRole(null)
                        setAssign(null)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (!assign) {
                            if (!role) return ref.current.focus()
                            return revokeRole(role)
                        }
                        if (!selectedRole) return ref.current.focus()
                        return assignRole(selectedRole)
                    }}
                    startIcon={
                        updatingRole || assigningRole ? (
                            <CircularProgress
                                size="1em"
                                sx={{ color: "white" }}
                            />
                        ) : null
                    }
                >
                    {!assign ? `Revoke` : "Assign"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateSystemUser
