import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@mui/lab"
import { Typography, Box, CircularProgress } from "@mui/material"
import { DateTime } from "luxon"
import React, { useRef, useEffect, useState } from "react"
import useReactQueryInfinitQuery from "../Hooks/useInfinitQuery.js"
// import ActivityDetail from "./activityDetail.jsx"

const timeRanges = [
    "12-1 ቀን",
    "1-2 ቀን",
    "2-3 ቀን",
    "3-4 ቀን",
    "4-5 ቀን",
    "5-6 ቀን",
    "6-7 ቀን",
    "7-8 ቀን",
    "8-9 ቀን",
    "9-10 ቀን",
    "10-11 ቀን",
    "11-12 ቀን",
    "12-1 ማታ",
    "1-2 ማታ",
    "2-3 ማታ",
    "3-4 ማታ",
    "4-5 ማታ",
    "5-6 ማታ",
    "6-7 ማታ",
    "7-8 ማታ",
    "8-9 ማታ",
    "9-10 ማታ",
    "10-11 ማታ",
    "11-12 ማታ",
]

function mapActivities(url, action, request) {
    switch (url[0]) {
        case "users":
            if (url[2] === "programs" && action === "PATCH")
                return {
                    title: "Updated Programs of User",
                    detail: { ...request },
                }
            if (url[2] === "programs" && action === "UPDATE")
                return {
                    title: "Assigned Programs to User",
                    detail: { ...request },
                }
            if (url[1] === "invite" && action === "PATCH")
                return {
                    title: "Updated Invited User Programs",
                    detail: { ...request },
                }
            if (url[1] === "invite" && action === "POST")
                return { title: "Invited User", detail: { ...request } }
            if (url[2] === undefined && action === "PATCH")
                return { title: "Updated User Status", detail: { ...request } }
            if (url[2] === "roles" && action === "POST")
                return {
                    title: "Assigned Role to User",
                    detail: { ...request },
                }
            if (url[2] === "roles" && action === "PATCH")
                return { title: "Revoked Role of User", detail: { ...request } }
            if (url[1] === "phone" && action === "POST")
                return {
                    title: "Searched user by phone",
                    detail: { ...request },
                }
            break
        case "programs":
            if (action === "POST")
                return {
                    title: "Created Program",
                    detail: {
                        ...request,
                        date_limit_weekdays:
                            request?.date_limit &&
                            request?.date_limit_weekdays?.map(
                                (index) =>
                                    [
                                        "Sunday",
                                        "Monday",
                                        "Tuesday",
                                        "Wednesday",
                                        "Thursday",
                                        "Friday",
                                        "Saturday",
                                    ][index]
                            ),
                        spending_limit_amount:
                            request?.spending_limit &&
                            request?.spending_limit_amount,
                        time_limit_times:
                            request?.time_limit &&
                            request?.time_limit_times?.map(
                                (indx) => timeRanges[indx]
                            ),
                        trip_limit_amount:
                            request?.trip_limit && request?.trip_limit_amount,
                        trip_limit: undefined,
                        spending_limit: undefined,
                        has_expire_date: undefined,
                        date_limit: undefined,
                    },
                }
            if (action === "PATCH")
                return {
                    title: "Updated Program Detail",
                    detail: {
                        ...request,
                        date_limit_weekdays:
                            request?.date_limit &&
                            request?.date_limit_weekdays?.map(
                                (index) =>
                                    [
                                        "Sunday",
                                        "Monday",
                                        "Tuesday",
                                        "Wednesday",
                                        "Thursday",
                                        "Friday",
                                        "Saturday",
                                    ][index]
                            ),
                        spending_limit_amount:
                            request?.spending_limit &&
                            request?.spending_limit_amount,
                        time_limit_times:
                            request?.time_limit &&
                            request?.time_limit_times?.map(
                                (indx) => timeRanges[indx]
                            ),
                        trip_limit_amount:
                            request?.trip_limit && request?.trip_limit_amount,
                        trip_limit: undefined,
                        spending_limit: undefined,
                        has_expire_date: undefined,
                        date_limit: undefined,
                    },
                }
            break
        case "roles":
            if (action === "PATCH")
                return { title: "Updated Role", detail: { ...request } }
            if (action === "POST")
                return { title: "Created Role", detail: { ...request } }
            break
        case "tips":
            if (action === "PATCH")
                return {
                    title: "Updated Tipping Configuration",
                    detail: { ...request },
                }
            break
        default:
            break
    }
    return null
}

function Activity(props) {
    const { date, userInfo, activity } = props || {}
    const { title } = activity || {}
    return (
        <TimelineContent>
            <Typography variant="body2" sx={{ color: "gray" }}>
                {date}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                {userInfo?.first_name} {userInfo?.middle_name}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    color: "secondary.main",
                    fontWeight: 900,
                    wordSpacing: ".25em",
                    letterSpacing: ".07em",
                }}
            >
                {title}
            </Typography>
            {/* <ActivityDetail detail={detail} /> */}
        </TimelineContent>
    )
}

function Connector({ end }) {
    return (
        <TimelineSeparator>
            <TimelineDot color="secondary" />
            {!end && <TimelineConnector />}
        </TimelineSeparator>
    )
}

function ActivityLog({ url: endPoint, filter }) {
    const scrollArea = useRef(null)
    const targetElement = useRef(null)

    const [observer, setObserver] = useState(null)

    const {
        data: activities,
        fetchNextPage,
        isLoading,
        isFetchingNextPage: isFetching,
    } = useReactQueryInfinitQuery({
        url: endPoint,
        filter: filter,
    })

    useEffect(() => {
        if (scrollArea && targetElement) {
            const options = {
                root: scrollArea.current,
                rootMargin: "0px",
                threshold: 0,
            }
            setObserver(
                new IntersectionObserver((entries) => {
                    if (entries[0]?.isIntersecting) fetchNextPage()
                }, options)
            )
        }
    }, [fetchNextPage])

    useEffect(() => {
        if (observer && targetElement) observer.observe(targetElement.current)
    }, [observer, targetElement])

    // PREPARE DATA TO DISPLAY
    const activityPages = activities?.pages?.map((el) => el?.data?.data)

    const activitiesList = activityPages?.reduce(
        (prev, current) => [...prev, ...current],
        []
    )

    const activitiesArray = activitiesList?.map((el) => {
        const {
            created_at: date,
            id: activityId,
            action,
            url,
            user_id: user,
            request_object: request,
            user_info: userInfo,
        } = el
        const parsedUrl = url?.split("/").slice(4)

        return {
            date: DateTime.fromISO(date).toFormat("EEEE, ff"),
            user,
            activity: mapActivities(parsedUrl, action, request),
            userInfo,
            activityId,
        }
    })

    return (
        <Box ref={scrollArea} sx={{ height: "500px", overflowY: "scroll" }}>
            <Timeline sx={{ gap: "1em" }}>
                {activitiesArray?.map((el, index) => (
                    <TimelineItem
                        key={el?.activityId}
                        sx={{
                            "&.MuiTimelineItem-root": {
                                ":before": {
                                    padding: "0px, 0px",
                                    flex: "none",
                                    "&:-webkit-flex": "none",
                                },
                            },
                        }}
                    >
                        <Connector end={activitiesArray.length - 1 === index} />
                        <Activity {...el} />
                    </TimelineItem>
                ))}
                <TimelineItem
                    sx={{
                        "&.MuiTimelineItem-root": {
                            ":before": {
                                padding: "0px, 0px",
                                flex: "none",
                                "&:-webkit-flex": "none",
                            },
                        },
                    }}
                >
                    <TimelineContent>
                        <Box
                            ref={targetElement}
                            sx={{
                                // position: "absolute",
                                bottom: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {(isLoading || isFetching) && (
                                <CircularProgress
                                    size="1.5em"
                                    sx={{ color: "lightgray" }}
                                />
                            )}
                        </Box>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </Box>
    )
}

export default ActivityLog
