import { find, isEqual } from "lodash"
import { useSnackbar } from "notistack"
import { useContext, useRef } from "react"
import { useMutation } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"
import useFetchEntities from "./useFetchEntity.js"

const useInviteUsers = () => {
    // here what we need is user phone, program Id

    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)
    const companyPrograms = useFetchEntities("programs")

    const { enqueueSnackbar } = useSnackbar()

    // findId(programj){ loop through all the programs}

    const invite = useRef([])

    const { mutate, isLoading } = useMutation((data) =>
        axiosPrivate.post(`corporate/${companyId}/users/invite`, {
            invite_users: data,
        })
    )

    const handleInvitation = (value) => {
        // BACKEND DOESN'T ACCEPT PROGRAM NAME, ONLY PROGRAM ID
        const programIds = value?.programs
            ?.split(",")
            ?.map((e) => e.trim())
            ?.map(
                (prog) =>
                    companyPrograms?.data?.data?.data.filter(
                        (ele) => ele.name === prog
                    )[0]?.id
            )
        // BACKEND DOESN'T ACCEPT AN ARRAY OF VALUES FOR PROGRAMIDS SO, FLATTEN THE ARRAY
        programIds?.forEach((id) => {
            const val = {
                phone: value.phone,
                program_id: id,
                guest: value.guest,
            }
            if (find(invite.current, (el) => isEqual(el, val))) return
            invite.current = [...invite.current, val]
        })
    }

    return {
        inviteUser: handleInvitation,
        requestInvitation: (callback) => {
            mutate(invite.current, {
                onSuccess: () => {
                    enqueueSnackbar("users invited successfully", {
                        variant: "success",
                    })
                    callback()
                },
                onError: (error) => {
                    const fieldError =
                        error.response?.data?.error?.field_error[0]?.description
                    const errorMessage = error.response?.data?.error?.message
                    enqueueSnackbar(
                        fieldError || errorMessage || "Request Failed",
                        {
                            variant: "error",
                        }
                    )
                },
            })
        },
        isLoading,
        clearInvite: () => {
            invite.current = []
        },
    }
}

export default useInviteUsers
