// import { Warning } from "@mui/icons-material"
import { Box } from "@mui/material"
import React from "react"
import accessDenied from "../Assets/accessDenied.jpg"
import useBreakPoints from "../Hooks/useBreakPoints.js"

function RestrictedAccess() {
    const { xs, md } = useBreakPoints()
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "4em",
                color: "secondary.main",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5em",
                }}
            >
                <img
                    style={{
                        width: "100%",
                        height: xs && !md ? "300px" : `calc(100vh - 180px)`,
                    }}
                    src={accessDenied}
                    alt="access denied"
                />
            </Box>
        </Box>
    )
}

export default RestrictedAccess
