import { useContext } from "react"
import { useQuery } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"

// Fetch/Get an Entity Details Hook
const useFetchEntities = (endPoint) => {
    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)

    return useQuery([companyId, endPoint], () =>
        axiosPrivate
            .get(`/corporate/${companyId}/${endPoint}`, {
                params: {
                    page: 0,
                    per_page: -1,
                },
            })
            .then((response) => response)
    )
}

export default useFetchEntities
