import { FileDownload } from "@mui/icons-material"
import {
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material"
import React, { useRef, useState } from "react"
import useExport from "../Hooks/useExportServer.js"
import handleClientExport from "../Utils/handleClientExport.js"

function ExportCustome({ table, endPoint, model, formatter, pageFilter }) {
    // EXPORT MODAL STATES
    const [open, setOpen] = useState(false)
    const ref = useRef()

    const { columnFilters } = table.getState()

    // EXPORT All DATA, IT ACCEPTS PAGE FILTER IF THE PAGE(TABLE) ITSELF IS OBTAINED BY FILTERING ANOTHER TABLE
    const { refetch: exportAll } = useExport(endPoint, pageFilter, formatter)

    // EXPORT FILTERED DATA
    const { refetch: exportFiltered } = useExport(endPoint, model, formatter)

    return (
        <Box
            sx={{
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
            }}
        >
            <Menu
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem
                    onClick={() => {
                        exportAll()
                        setOpen(false)
                    }}
                >
                    {" "}
                    <ListItemIcon>
                        <FileDownload />
                    </ListItemIcon>
                    <ListItemText>All Data</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={
                        table.getPrePaginationRowModel().rows.length === 0 ||
                        columnFilters.length === 0
                    }
                    onClick={() => {
                        exportFiltered()
                        setOpen(false)
                    }}
                >
                    <ListItemIcon>
                        <FileDownload />
                    </ListItemIcon>
                    <ListItemText>Filtered</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={table.getRowModel().rows.length === 0}
                    onClick={() => {
                        handleClientExport(table.getRowModel().rows)
                        setOpen(false)
                    }}
                >
                    <ListItemIcon>
                        <FileDownload />
                    </ListItemIcon>
                    <ListItemText> Current Page</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={
                        !table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()
                    }
                    onClick={() => {
                        handleClientExport(table.getSelectedRowModel().rows)
                        setOpen(false)
                    }}
                >
                    <ListItemIcon>
                        <FileDownload />
                    </ListItemIcon>
                    <ListItemText>Selected</ListItemText>
                </MenuItem>
            </Menu>

            <IconButton ref={ref} size="small" onClick={() => setOpen(true)}>
                <FileDownload />
            </IconButton>
        </Box>
    )
}

export default ExportCustome
