import React, { useContext } from "react"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import { useQuery } from "react-query"
import { AlbumOutlined, RoomOutlined } from "@mui/icons-material"

import Timeline from "@mui/lab/Timeline"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"

import { TimelineDot } from "@mui/lab"
// import OSMPolyline from "./openSteetMap.jsx"
import ErrorBoundary from "./errorBoundary.jsx"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"
import AuthContext from "../Context/auth_provider.jsx"
import LoadingPage from "./loadingPage.jsx"
import useBreakPoints from "../Hooks/useBreakPoints.js"
import MapLibre from "./mapLibre.jsx"

function ViewTripDetails({ detail, setDetail }) {
    const axiosPrivate = useAxiosPrivate()
    const { xs, md, sm } = useBreakPoints()

    const { companyId } = useContext(AuthContext)
    let tripDetail

    if (detail.trip_type === "Ride Trip") {
        tripDetail = (tripId) =>
            axiosPrivate.get(`corporate/${companyId}/trips/ride/${tripId}`)
    } else {
        tripDetail = (tripId) =>
            axiosPrivate.get(`corporate/${companyId}/trips/${tripId}`)
    }

    const {
        data: tripInfo,
        isLoading,
        isSuccess,
        isError,
    } = useQuery(
        ["trip-detail", detail?.trip_id],
        () => tripDetail(detail?.trip_id),
        { enabled: !!detail, refetchOnWindowFocus: false }
    )

    const startTime = DateTime.fromISO(tripInfo?.data?.data?.start_time)
    const endTime = DateTime.fromISO(tripInfo?.data?.data?.end_time)

    const tripDuration = endTime
        .diff(startTime, ["days", "hours", "minute"])
        .toObject()

    return (
        <Dialog
            fullScreen={xs && !md}
            maxWidth="md"
            PaperProps={{
                sx: {
                    minWidth: md && "70ch",
                },
            }}
            onClose={() => {
                setDetail(null)
            }}
            open={!!detail}
        >
            <DialogTitle
                sx={{
                    margin: "auto",
                    mb: "1em",
                    textAlign: "center",
                    backgroundColor: "common.main",
                    width: "100%",
                    fontWeight: "700px",
                }}
            >
                Trip History
            </DialogTitle>
            <DialogContent>
                {isLoading && (
                    <LoadingPage message="...fetching trip details" />
                )}
                {isError && !tripInfo && (
                    <Typography>
                        We couldn`t retrieve trip data due to an error. Please
                        try again later
                    </Typography>
                )}
                {!!tripInfo && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".3em",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: xs && !md && "column",
                                justifyContent: "space-around",
                                backgroundColor: "common.main",
                                px: "2em",
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1.5,
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "3em",
                                    paddingTop: "1.5em",
                                    paddingBottom: "1.5em",
                                }}
                            >
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ color: "gray" }}>
                                        Trip time
                                    </Typography>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        {(() => {
                                            const hours = Math.floor(
                                                tripDuration.hours
                                            )
                                            const mins = Math.floor(
                                                tripDuration.minutes
                                            )

                                            const minsRounded = Math.round(mins)

                                            return hours > 0
                                                ? ` ${hours} hr ${minsRounded} minutes`
                                                : `${minsRounded} minutes`
                                        })()}
                                    </Typography>
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ color: "gray" }}>
                                        Amount
                                    </Typography>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        {(() => {
                                            const amount =
                                                tripInfo?.data?.data?.cost
                                            const rounded =
                                                Math.round(amount * 10) / 10

                                            return rounded
                                                ? `${rounded} ETB`
                                                : "No Data"
                                        })()}
                                    </Typography>
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ color: "gray" }}>
                                        Program
                                    </Typography>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        {detail?.program_name}
                                    </Typography>
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ color: "gray" }}>
                                        Total Km
                                    </Typography>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        {(() => {
                                            const distance =
                                                tripInfo?.data?.data?.distance
                                            if (distance) {
                                                const dKm = distance / 1000
                                                const rounded =
                                                    Math.round(dKm * 100) / 100
                                                return rounded
                                            }
                                            return "No Data"
                                        })()}
                                    </Typography>
                                </Box>
                            </Box>
                            {sm && (
                                <Timeline sx={{ flex: 3, gap: "15px" }}>
                                    <TimelineItem
                                        sx={{
                                            "&.MuiTimelineItem-root": {
                                                ":before": {
                                                    padding: "0px, 0px",
                                                    flex: "none",
                                                    "&:-webkit-flex": "none",
                                                },
                                            },
                                        }}
                                    >
                                        <TimelineSeparator>
                                            <TimelineDot
                                                sx={{
                                                    backgroundColor: "#f5f5f5",
                                                    border: "none",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                <AlbumOutlined
                                                    sx={{
                                                        color: "#00FF00",
                                                    }}
                                                />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Box>
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    {DateTime.fromISO(
                                                        startTime
                                                    ).toFormat("ff")}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="common"
                                                >
                                                    {
                                                        tripInfo?.data?.data
                                                            ?.start_point_name
                                                    }
                                                </Typography>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem
                                        sx={{
                                            "&.MuiTimelineItem-root": {
                                                ":before": {
                                                    padding: "0px, 0px",
                                                    flex: "none",
                                                    "&:-webkit-flex": "none",
                                                },
                                            },
                                        }}
                                    >
                                        <TimelineSeparator>
                                            <TimelineDot
                                                sx={{
                                                    backgroundColor: "#f5f5f5",
                                                    border: "none",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                <RoomOutlined
                                                    color="primary"
                                                    size="large"
                                                />
                                            </TimelineDot>
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    {DateTime.fromISO(
                                                        endTime
                                                    ).toFormat("ff")}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="common"
                                                >
                                                    {
                                                        tripInfo?.data?.data
                                                            ?.end_point_name
                                                    }
                                                </Typography>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            )}
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                height: "300px",
                                width: "100%",
                            }}
                        >
                            <ErrorBoundary>
                                <MapLibre
                                    path={tripInfo?.data?.data?.poly_line}
                                />
                            </ErrorBoundary>
                            {/* <OSMPolyline
                                path={tripInfo?.data?.data?.poly_line}
                            /> */}
                        </Box>
                    </Box>
                )}

                {!tripInfo && isSuccess && (
                    <Typography>
                        There is no trip data for this transaction
                    </Typography>
                )}
            </DialogContent>
            <Divider sx={{ my: ".5em" }} />
            <DialogActions>
                <Button
                    onClick={() => {
                        setDetail(null)
                    }}
                    variant="contained"
                    color="common"
                    sx={{
                        mr: "2em",
                        mb: ".5em",
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewTripDetails
