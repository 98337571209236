import React, { useContext, useMemo, useState } from "react"
import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import { useQuery } from "react-query"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import bmsPic from "../../Assets/bmsCropped.png"
import fallback from "../../Assets/fallback.png"
import AuthContext from "../../Context/auth_provider.jsx"
import LoadingPage from "../../Component/loadingPage.jsx"
import { RP_ASSETS_URL, BASE_URL, VERSION } from "../../Utils/config.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"

export function Image(props) {
    const { src, alt, fit, text } = props
    return (
        <Box
            sx={{ position: "relative", display: "flex", alignItems: "center" }}
        >
            <img
                width="100%"
                height="100%"
                src={src}
                onError={(e) => {
                    e.currentTarget.src = fallback
                }}
                alt={alt}
                style={{ objectFit: fit }}
            />
            {text && (
                <Typography
                    variant="h4"
                    sx={{
                        position: "absolute",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        bottom: "1em",
                        color: "white",
                        letterSpacing: ".08em",
                    }}
                >
                    {text}
                </Typography>
            )}
        </Box>
    )
}

function Company({ company, onSelect, selected }) {
    const { logo, name, id } = company
    return (
        <Paper
            sx={{
                p: ".8em",
                "&:hover": {
                    backgroundColor: "lightgray",
                },
                border: selected ? "2px solid black" : "none",
                cursor: "pointer",
                "&.MuiPaper-elevation": {
                    boxShadow: "2px 3px 3px #00000021",
                },
            }}
        >
            <Grid
                container
                spacing={1.2}
                alignItems="center"
                onClick={() => onSelect(id)}
            >
                <Grid
                    item
                    xs={2}
                    sm={1}
                    md={2}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Image
                            src={`${BASE_URL}/${VERSION}/${RP_ASSETS_URL}/company_logo/${logo}`}
                            alt="logo"
                            fit="fill"
                        />
                    </Box>
                </Grid>
                <Grid item xs={7} md={9}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                        {name}
                    </Typography>
                    <Typography> Employees</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export const CompanyToggle = React.createContext()
export const CompanyInfo = React.createContext()

function UserCompany({ children }) {
    const axiosPrivate = useAxiosPrivate()

    const savedCompany = window.localStorage.getItem("companyId")

    const [selected, setSelected] = useState(savedCompany)

    const { data, isSuccess, isError, isLoading, refetch } = useQuery(
        ["users", "permissions"],
        () => axiosPrivate.get("users/permissions").then((res) => res.data)
    )

    const { companyId, setCompanyId } = useContext(AuthContext)

    const companyList = data?.data?.map((el) => el.company)

    const contextValue = useMemo(
        () => ({
            toggle: () => setSelected(false),
            companyList,
            data: data?.data,
        }),
        [companyList, data]
    )

    // SELECTED COMPANY INFO FOR COMPANY INFO CONTEXT
    const companyInfo = useMemo(
        () => ({
            refetch: refetch, // NEEDED TO UPDATE COMPANY INFORMATION LIKE TIPPING
            selectedCompany: companyList?.filter(
                (comp) => comp.id === selected
            )?.[0],
            userPermissions: data?.data?.filter(
                (el) => el.company.id === selected
            )?.[0]?.permissions,
        }),
        [companyList, refetch, selected, data]
    )

    if (!selected && companyList?.length > 1) {
        return (
            <Grid
                container
                spacing={1}
                sx={{
                    width: { lg: "80%", md: "90%", xs: "95%" },
                    display: "flex",
                    margin: "auto",
                    marginTop: { md: "5em" },
                    marginLeft: { lg: "6em" },
                    justifyContent: "space-between",
                }}
            >
                <Grid item md={6} xs={12} sx={{ p: ".5em" }}>
                    <Image src={bmsPic} alt="company-list" fit="cover" />
                </Grid>
                <Grid item md={5} xs={12}>
                    <Box
                        sx={{
                            p: "2em",
                            pt: "1em",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1em",
                        }}
                    >
                        <Typography sx={{ fontWeight: "500" }}>
                            Choose company and continue
                        </Typography>
                        {companyList?.map((comp) => (
                            <Company
                                key={comp.id}
                                company={comp}
                                selected={companyId === comp.id}
                                onSelect={(compId) => setCompanyId(compId)}
                            />
                        ))}
                        <Button
                            disabled={!companyId}
                            variant="contained"
                            sx={{
                                height: "3em",
                                color: "#fff",
                                backgroundColor: "gray",
                            }}
                            onClick={() =>
                                setSelected(() => {
                                    if (companyId) {
                                        window.localStorage.setItem(
                                            "companyId",
                                            companyId
                                        )
                                        return companyId
                                    }
                                    return false
                                })
                            }
                        >
                            Continue
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if (companyList?.length === 1 && !selected) {
        setCompanyId(companyList[0].id)
        setSelected(companyList[0].id)
    }

    if (selected && !companyId)
        return (
            <Box sx={{ pt: "5em", color: "secondary.main" }}>
                <LoadingPage message="...Please wait a moment" />
            </Box>
        )

    return (
        <CompanyToggle.Provider value={contextValue}>
            {companyList?.length > 0 && selected && !isLoading && (
                <CompanyInfo.Provider value={companyInfo}>
                    {children}
                </CompanyInfo.Provider>
            )}
            {companyList?.length === 0 && isSuccess && <RestrictedAccess />}
            {isError && !selected && (
                <p>
                    Something went wrong. <br /> Please Retry after a moment
                </p>
            )}
            {isLoading && (
                <Box sx={{ pt: "5em", color: "secondary.main" }}>
                    <LoadingPage message="...Please wait a moment" />
                </Box>
            )}
        </CompanyToggle.Provider>
    )
}

export default UserCompany
