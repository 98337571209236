import {
    Autocomplete,
    Box,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import MaterialReactTable from "material-react-table"
import { DateTime } from "luxon"
import React, {
    useState,
    useMemo,
    useRef,
    useCallback,
    useContext,
} from "react"
import { useSnackbar } from "notistack"
import { useMutation, useQueryClient } from "react-query"
import { AttachMoney, Edit, HighlightOff, TaskAlt } from "@mui/icons-material"
import { Link } from "react-router-dom"
import AddPeople from "../../Component/addPeople.jsx"
import useTableProps from "../../Hooks/useTableProps.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import useTableState from "../../Hooks/useTableState.js"
import StatusButton from "../../Component/statusButton.jsx"
import ConfirmationDialog from "../../Component/confirmationDialog.jsx"
import UpdatePrograms from "../../Component/updatePrograms.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import customeFilter from "../../Component/customFilterPanel.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import AuthContext from "../../Context/auth_provider.jsx"

const userFields = [
    {
        accessorKey: "first_name",
        header: "Name",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ row }) =>
            `${row.original.first_name} ${row.original.middle_name}`,
    },
    {
        accessorKey: "phone",
        header: "Phone No",
        size: 150,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "monthly_spent_amount",
        header: "Monthly Amount Spent",
        size: 150,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "total_spent_amount",
        header: "Total Amount Spent",
        size: 150,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "monthly_trips_used",
        header: "Monthly Trips Used",
        size: 150,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "total_trips_used",
        header: "Total Trips Used",
        size: 150,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "programs",
        header: "Programs",
        size: 300,
        filterVariant: "json",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => cell.getValue()[0].name,
    },
    // {
    //     accessorKey: "voucher",
    //     header: "Voucher",
    //     size: 200,
    //     enableColumnFilter: false,
    //     enableSorting: false,
    //     renderColumnFilterModeMenuItems: customeFilter,
    // },
    {
        accessorKey: "created_at",
        header: "Created At",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) =>
            `${DateTime.fromISO(cell.getValue()).toFormat("ff")}`,
    },
    {
        accessorKey: "status",
        header: " ",
        size: 230,
        filterVariant: "select",
        filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
        renderColumnFilterModeMenuItems: customeFilter,
        valueOptions: ["ACTIVE", "INACTIVE", "PENDING"],
    },
]

function CompanyUsers() {
    const {
        usersAssign,
        usersInvite,
        usersList,
        usersListInvited,
        usersPage,
        usersProgramsUpdate,
        usersInvitedProgramsUpdate,
    } = useUserPermissions()

    const { md } = useBreakPoints()
    // ADD COMPANY USER MODAL
    const [open, setOpen] = useState(false)
    // iSGUEST IS GONNA HAVE POSSIBLY 3 VALUES, `TRUE | FALSE | 'INVITED'`
    const [isGuest, setIsGuest] = useState(false)
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [user, setUser] = useState(null)

    // VOUCHER CONFIRMATION STATES
    const [voucherConfirmationMessage, setVoucherConfirmationMessage] =
        useState()
    const selectedUser = useRef()

    const { onChangeHandlers, state, params, density } = useTableState({
        columns: userFields,
    })
    // FILTER MODEL WITH SELECTED TAB
    const { filterModel, ...rest } = params
    let filterModelWithTab

    if (isGuest !== "invited") {
        filterModelWithTab = [
            ...filterModel,
            {
                column_field: "guest",
                operator_value: "is",
                value: isGuest ? "true" : "false",
            },
        ]
    } else {
        filterModelWithTab = filterModel
    }

    const requestParams = { filterModel: filterModelWithTab, ...rest }

    // COMPANY USERS
    const { data, isLoading, isFetching } = useFetchEntitiesPerPage(
        {
            endPoint: "users",
            ...requestParams,
        },
        { enabled: Boolean(usersList && isGuest !== "invited") }
    )
    // INVITED USERS
    const {
        data: invitedUsers,
        isFetching: fetchingInvited,
        isLoading: loadingInvited,
    } = useFetchEntitiesPerPage(
        {
            endPoint: "users/invite",
            ...params,
        },
        {
            enabled: Boolean(isGuest === "invited" && usersListInvited),
        }
    )

    const { enqueueSnackbar } = useSnackbar()
    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)

    const queryClient = useQueryClient()
    // STATUS UPDATE
    const { mutate, isLoading: statusChanging } = useMutation(
        (userData) =>
            axiosPrivate
                .patch(`corporate/${companyId}/users/${userData.id}`, {
                    status: userData?.status,
                })
                .then((res) => res?.data),
        {
            onError: (error) => {
                enqueueSnackbar(
                    `status update failed due to ${error.message}`,
                    { variant: "error" }
                )
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [companyId, "users"],
                })
                enqueueSnackbar("status updated successfully", {
                    variant: "success",
                })
            },
        }
    )

    const handleStatusChange = useCallback(
        (status, companyUser) => {
            const { user_id: userId } = companyUser
            mutate({
                id: userId,
                status: status === false ? "INACTIVE" : "ACTIVE",
            })
        },
        [mutate]
    )

    const handleVocherSwitch = (checked, person) => {
        if (checked) {
            setVoucherConfirmationMessage({
                header: "Enable Voucher",
                body: "You are about to enable voucher feature for this person to use it for payment",
            })
        } else {
            setVoucherConfirmationMessage({
                header: "Disable Voucher",
                body: "You are about to disable voucher feature for this person affecting them to use it for payment",
            })
        }
        selectedUser.current = person
    }

    const handleVoucherConfirmation = () => {
        // TODO: the handler is called with the user data, so updated the voucher status here
        // when the udate is successful, close the modal
        setVoucherConfirmationMessage("")
    }

    const actionColumn = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    pr: ".5em",
                }}
            >
                <StatusButton
                    loading={statusChanging}
                    onChange={(status) =>
                        handleStatusChange(status, row.original)
                    }
                    status={row.original.status}
                />
                <Link
                    to={`${row.original.user_id}/transactions`}
                    state={row.original}
                >
                    <Tooltip title="Transactions">
                        <IconButton size="small" sx={{ color: "primary.main" }}>
                            <AttachMoney />
                        </IconButton>
                    </Tooltip>
                </Link>
            </Box>
        ),
        [handleStatusChange, statusChanging]
    )

    const programEditColumn = useCallback(
        ({ cell, row }) => {
            const noPrograms = cell.getValue()?.length === 0

            return (
                !noPrograms && (
                    <Autocomplete
                        readOnly
                        size={density === "compact" ? "small" : "medium"}
                        multiple
                        value={cell.getValue()}
                        options={[]}
                        getOptionLabel={(opt) => opt?.name}
                        renderTags={(value) =>
                            value?.map((el) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        m: ".05em",
                                        backgroundColor: el?.user_program_status
                                            ? "success.main"
                                            : "error.main",
                                        color: "white",
                                        borderRadius: "20px",
                                        pr: "1em",
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{
                                            color: "white",
                                        }}
                                    >
                                        {el?.user_program_status ? (
                                            <TaskAlt fontSize=".1em" />
                                        ) : (
                                            <HighlightOff fontSize=".1em" />
                                        )}
                                    </IconButton>
                                    <Typography variant="subtitle2" noWrap>
                                        {el?.name}
                                    </Typography>
                                </Box>
                            ))
                        }
                        renderInput={(paras) => (
                            <TextField label="Programs" {...paras} />
                        )}
                        popupIcon={
                            isGuest === "invited"
                                ? usersInvitedProgramsUpdate && (
                                      <IconButton
                                          size="small"
                                          onClick={() => {
                                              setUser(row.original)
                                              setIsEditOpen(true)
                                          }}
                                      >
                                          <Edit size=".3em" />
                                      </IconButton>
                                  )
                                : usersProgramsUpdate && (
                                      <IconButton
                                          size="small"
                                          onClick={() => {
                                              setUser(row.original)
                                              setIsEditOpen(true)
                                          }}
                                      >
                                          <Edit size=".3em" />
                                      </IconButton>
                                  )
                        }
                        sx={{
                            border: "none",
                            "&:hover": {
                                cursor: "pointer",
                                backgroundColor: "common.main",
                            },
                            ".MuiAutocomplete-endAdornment": {
                                display: !usersProgramsUpdate && "none",
                            },
                            ".MuiChip-filled": {
                                backgroundColor: "error.main",
                                color: "white",
                            },
                            ".MuiInputBase-root > input": {
                                display: "none",
                            },
                        }}
                    />
                )
            )
        },
        [usersProgramsUpdate, isGuest, usersInvitedProgramsUpdate, density]
    )

    const voucherSwitchColumn = useCallback(
        ({ cell, row }) => (
            <Switch
                checked={cell.getValue()}
                onChange={(checked) =>
                    handleVocherSwitch(checked, row.original)
                }
            />
        ),
        []
    )

    const customDateFilter = useCallback(
        (props) => <CustomeDateFilter {...props} />,
        []
    )

    // ADD RENDER CELL METHOD TO COL DEFS
    const colDefs = useMemo(
        () =>
            userFields.map((el) => {
                if (el.accessorKey === "created_at")
                    return {
                        ...el,
                        Filter: customDateFilter,
                    }
                if (el.accessorKey === "status") {
                    if (isGuest !== "invited") {
                        return {
                            ...el,
                            Cell: actionColumn,
                        }
                    }
                    return { ...el, size: 0 }
                }
                if (el.accessorKey === "programs") {
                    return {
                        ...el,
                        Cell: programEditColumn,
                    }
                }
                if (el.accessorKey === "voucher") {
                    return {
                        ...el,
                        Cell: voucherSwitchColumn,
                    }
                }
                return el
            }),
        [
            programEditColumn,
            voucherSwitchColumn,
            actionColumn,
            customDateFilter,
            isGuest,
        ]
    )

    const handleTabChange = (selectedTab) =>
        selectedTab === "invited"
            ? setIsGuest("invited")
            : setIsGuest(selectedTab === "guest")

    const { props } = useTableProps({
        columns: colDefs,
        actionButtonProps: (usersAssign || usersInvite) && {
            actionName: "Add People",
            onClick: () => setOpen(true),
        },
        tabProps: {
            tabs: [
                usersList && { value: "members", label: "Members" },
                usersList && { value: "guest", label: "Guest" },
                usersListInvited && { value: "invited", label: "Invited" },
            ],
            onChange: handleTabChange,
        },
        exportProps: {
            dataEndPoint: "users",
            model: filterModelWithTab,
        },
        state: {
            ...state,
            showSkeletons: loadingInvited || isLoading,
            showProgressBars: isFetching || fetchingInvited,
            columnVisibility: {
                status: isGuest !== "invited",
                first_name: isGuest !== "invited",
                total_spent_amount: isGuest !== "invited",
                monthly_spent_amount: isGuest !== "invited",
                total_trips_used: isGuest !== "invited",
                monthly_trips_used: isGuest !== "invited",
            },
        },
    })

    if (!usersPage) return <RestrictedAccess />

    return (
        <Box>
            <AddPeople open={open} setOpen={setOpen} />
            <UpdatePrograms
                user={user}
                open={isEditOpen}
                setOpen={setIsEditOpen}
                invited={isGuest === "invited"}
            />
            <ConfirmationDialog
                message={voucherConfirmationMessage}
                onConfirmation={handleVoucherConfirmation}
            />
            <Box sx={{ backgroundColor: "white" }}>
                <MaterialReactTable
                    data={
                        isGuest === "invited"
                            ? invitedUsers?.data?.data || []
                            : data?.data?.data || []
                    }
                    rowCount={
                        isGuest === "invited"
                            ? invitedUsers?.data?.meta_data?.total || 0
                            : data?.data?.meta_data?.total || 0
                    }
                    initialState={{
                        columnPinning: { right: md ? ["status"] : null },
                    }}
                    {...props}
                    {...onChangeHandlers}
                />
            </Box>
        </Box>
    )
}

export default CompanyUsers
