import React, { useCallback, useContext } from "react"
import { Link } from "react-router-dom"
import MaterialReactTable from "material-react-table"
import { Button, IconButton, Tooltip } from "@mui/material"
import { Visibility } from "@mui/icons-material"
import { DateTime } from "luxon"
import customeFilter from "../../Component/customFilterPanel.jsx"
import useTableProps from "../../Hooks/useTableProps.js"
import useTableState from "../../Hooks/useTableState.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import { CompanyInfo } from "../SystemUsers/companyList.jsx"
import { formatToTwoDecimalPlaces } from "../../Utils/dataFormat.js"

const invoiceFields = [
    {
        accessorKey: "created_at",
        header: "Time",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "number",
        header: "Invoice No",
        size: 200,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "item",
        header: "Item",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "due_balance",
        header: "Due Balance",
        size: 200,
        filterVariant: "number",
        Cell: ({ row }) => formatToTwoDecimalPlaces(row?.original?.due_balance),
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "quantity",
        header: "Quantity",
        size: 200,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "invoice_type",
        header: "Invoice Type",
        size: 200,
        filterVariant: "select",
        filterSelectOptions: ["PREPAID", "POSTPAID"],
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "status",
        header: "Status",
        size: 200,
        filterVariant: "select",
        filterSelectOptions: ["PENDING", "APPROVED", "REJECTED", "OVERDUE"],
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => (
            <Button color="secondary" sx={{ backgroundColor: "#e8ffe5" }}>
                {cell.getValue()}
            </Button>
        ),
    },
    {
        accessorKey: "due_date",
        header: "Due Date",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "action",
        header: "Action",
        size: 200,
        enableColumnFilter: false,
    },
]

function Invoice() {
    const { invoiceList, invoicePage } = useUserPermissions()
    const { params, state, onChangeHandlers } = useTableState({
        columns: invoiceFields,
    })

    const { md } = useBreakPoints()

    const { selectedCompany } = useContext(CompanyInfo)
    const accountType = selectedCompany?.account_type

    const { data, isFetching, isLoading } = useFetchEntitiesPerPage(
        {
            endPoint: "invoices",
            ...params,
        },
        { enabled: invoiceList && accountType === "POSTPAID" }
    )

    const {
        data: topups,
        isFetching: fetchingTopups,
        isLoading: loadingTopups,
    } = useFetchEntitiesPerPage(
        {
            endPoint: "top_ups",
            ...params,
        },
        { enabled: invoiceList && accountType === "PREPAID" }
    )

    const actionColumn = useCallback(
        ({ row }) => (
            <Link to={`${row.original?.number}/detail`} state={row.original}>
                <Tooltip title="print invoice">
                    <IconButton sx={{ color: "primary.main" }}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            </Link>
        ),
        []
    )

    const columnsWithActions = invoiceFields.map((el) => {
        if (el.accessorKey === "action") {
            return {
                ...el,
                Cell: actionColumn,
            }
        }

        return el
    })

    const { props } = useTableProps({
        columns: columnsWithActions,
        exportProps: {
            dataEndPoint: "users",
            model: params.filterModel,
        },
        state: {
            ...state,
            showSkeletons: isLoading || loadingTopups,
            showProgressBars: isFetching || fetchingTopups,
        },
    })

    if (!invoicePage) return <RestrictedAccess />

    return (
        <MaterialReactTable
            data={
                accountType === "POSTPAID"
                    ? data?.data?.data || []
                    : topups?.data?.data || []
            }
            initialState={{
                columnPinning: {
                    right: [md ? "action" : null],
                },
            }}
            {...onChangeHandlers}
            {...props}
        />
    )
}

export default Invoice
