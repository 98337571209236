import { CloudUploadOutlined } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import React, { useState } from "react"

import { useCSVReader, formatFileSize } from "react-papaparse"

function CSVUploader({ onChange }) {
    const { CSVReader } = useCSVReader()
    const [hover, setHover] = useState(false)

    return (
        <CSVReader
            onUploadAccepted={(results) => {
                // THE TIMEOUT IS TO COMMUNICATE TO THE USER THAT THE FILE UPLOAD WAS SUCCESSFUL
                setTimeout(() => {
                    onChange(results?.data)
                }, 2000)
            }}
            onDragOver={(event) => {
                event.preventDefault()
                setHover(true)
            }}
            onDragLeave={(event) => {
                event.preventDefault()
                setHover(false)
            }}
            config={{
                header: true,
            }}
        >
            {({ getRootProps, acceptedFile, ProgressBar }) => (
                <Box
                    {...getRootProps()}
                    sx={{
                        backgroundColor: "common.main",
                        height: "150px",
                        borderRadius: "10px",
                        border: hover ? "1px solid green" : null,
                    }}
                >
                    {acceptedFile ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography>
                                {formatFileSize(acceptedFile.size)}
                            </Typography>
                            <Typography>{acceptedFile.name}</Typography>
                            <ProgressBar />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <CloudUploadOutlined />
                            <Typography sx={{ fontWeight: "900" }}>
                                Drop your files here or
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{
                                    height: "3em",
                                    borderRadius: "1.5em",
                                    color: "white",
                                }}
                            >
                                Upload from computer
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
        </CSVReader>
    )
}

export default CSVUploader
