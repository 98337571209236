import React, { useEffect } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Alert, CircularProgress, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import useDeleteEntity from "../Hooks/useDeleteEntity.js"

export default function DeleteEntity(props) {
    const { open, setOpen, deleteUrl, message, refetch } = props
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

    const { isLoading, mutate, data } = useDeleteEntity(`${deleteUrl}`)

    const handleDelete = () => {
        mutate()
    }
    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (data) {
            setOpen(false)
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="delete-dialog-title"
            >
                <DialogTitle
                    id="delete-dialog-title"
                    sx={{ bgcolor: "#fafafa" }}
                >
                    <Typography
                        variant="h5"
                        sx={{ textAlign: "center", fontWeight: "bold" }}
                    >
                        Delete {message}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ m: 2 }}>
                    <DialogContentText>
                        <Typography variant="body1" sx={{ mt: "1em" }}>
                            Are you sure, do you want to delete the selected{" "}
                            {message.toLowerCase()}?
                        </Typography>
                        <br />
                        <Alert variant="outlined" severity="warning">
                            This is an irriversible action!
                        </Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{ bgcolor: "#fafafa", textTransform: "none" }}
                >
                    <Button
                        variant="contained"
                        autoFocus
                        onClick={handleClose}
                        sx={{
                            color: "primary.main",
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleDelete}
                        autoFocus
                        color="error"
                        sx={{
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                    >
                        Delete
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "secondary.main",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
