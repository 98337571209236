import React, { useState, useEffect, useContext, useRef } from "react"
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    TextField,
    Typography,
} from "@mui/material"
import { useMutation, useQueryClient } from "react-query"
import { useSnackbar } from "notistack"
import { find } from "lodash"
import { Warning } from "@mui/icons-material"
import useSSOUsers from "../Hooks/useSSOUsers.js"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"
import AuthContext from "../Context/auth_provider.jsx"
import useFetchEntities from "../Hooks/useFetchEntity.js"
import { SSO_ASSETS_URL, SSO_BASE_URL, SSO_VERSION } from "../Utils/config.js"
import useUserPermissions from "../Hooks/useUserPermissions.js"

function User({ name, phone, src }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "common.main",
                py: "1em",
                px: "1em",
            }}
        >
            <Avatar
                style={{
                    height: "3em",
                    width: "3em",
                    borderRadius: "50%",
                    objectFit: "contain",
                }}
                src={src || " "}
            />
            <Typography
                variant="body1"
                sx={{ fontSize: "1.2em", textAlign: "center" }}
            >
                {name}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "1.2em" }}>
                +{phone}
            </Typography>
        </Box>
    )
}

function AssignSingleUser({ search, close }) {
    const { usersInvite, usersAssign } = useUserPermissions()
    const companyPrograms = useFetchEntities("programs")
    const [programs, setPrograms] = useState(null)
    const [invite, setInvite] = useState(false)
    const [guest, setGuest] = useState(false)

    const ref = useRef(null)

    const queryClient = useQueryClient()

    const showActionButtons =
        (invite && usersInvite) || (!invite && usersAssign)

    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)

    const { enqueueSnackbar } = useSnackbar()

    const { data: user, mutate, isLoading, isError } = useSSOUsers()

    useEffect(() => mutate({ phones: [search] }), [search, mutate])

    useEffect(() => {
        if (user?.ok && !user?.data) {
            setInvite(true)
        } else {
            setInvite(false)
        }
    }, [user])

    const { mutate: assignUser, isLoading: assigning } = useMutation(
        (d) =>
            axiosPrivate.post(`/corporate/${companyId}/programs/assign`, {
                program_users: d,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [companyId, "users"],
                })
                enqueueSnackbar("assignment request sent successfully", {
                    variant: "success",
                })
                close()
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError ||
                        errorMessage ||
                        "Couldn't Assign. Try again later please",
                    { variant: "error" }
                )
            },
        }
    )

    const { mutate: inviteUser, isLoading: inviting } = useMutation(
        (d) =>
            axiosPrivate.post(`/corporate/${companyId}/users/invite`, {
                invite_users: d,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [companyId, "users/invite"],
                })
                enqueueSnackbar("Invitation sent Successfully", {
                    variant: "success",
                })
                close()
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError ||
                        errorMessage ||
                        "Couldn't Invite user. Try again later please",
                    { variant: "error" }
                )
            },
        }
    )

    const handleProgram = (e, newVal) => {
        setPrograms(newVal)
    }

    const handleAssign = () => {
        const assignmentData = programs?.map((prog) => ({
            user_id: user?.data[0]?.id,
            program_id: find(
                companyPrograms?.data?.data?.data,
                (el) => el.name === prog
            ).id,
            guest: guest,
        }))
        assignUser(assignmentData)
    }

    const handleInvite = () => {
        const inviteData = programs?.map((prog) => ({
            phone: search,
            program_id: find(
                companyPrograms?.data?.data?.data,
                (el) => el.name === prog
            ).id,
            guest: guest,
        }))
        inviteUser(inviteData)
    }

    if (isLoading)
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress />
            </Box>
        )
    if (isError)
        return (
            <Box>
                <Typography>
                    Some problem interupted the search, please try again
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => close()}
                        sx={{ mt: "1em" }}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        )

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <Box>
                {user?.data ? (
                    <User
                        name={`${user?.data?.[0]?.first_name}
                            ${user?.data?.[0]?.last_name}`}
                        phone={user?.data[0]?.phone}
                        src={`${SSO_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${user?.data[0]?.profile_picture}`}
                    />
                ) : (
                    showActionButtons && (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1em",
                                justifyContent: "flex-start",
                                color: "red",
                            }}
                        >
                            <Warning />
                            <Typography variant="subtitle2">
                                The user does not exist in ridePlus, You can
                                send an invitation
                            </Typography>
                        </Box>
                    )
                )}
            </Box>
            {showActionButtons ? (
                <>
                    <Autocomplete
                        multiple
                        onChange={handleProgram}
                        options={
                            companyPrograms?.data?.data?.data?.map(
                                (ele) => ele.name
                            ) || []
                        }
                        renderInput={(params) => (
                            <TextField
                                inputRef={ref}
                                {...params}
                                label="Choose Programs"
                            />
                        )}
                    />
                    <FormControlLabel
                        checked={guest}
                        onChange={(e) => setGuest(e.target.checked)}
                        control={<Checkbox />}
                        label="Guest"
                    />
                    <Divider sx={{ my: "1em" }} />
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1em",
                            pr: "2em",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="common"
                            onClick={() => close()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={
                                assigning || inviting ? (
                                    <CircularProgress
                                        size="1em"
                                        sx={{ color: "white" }}
                                    />
                                ) : null
                            }
                            onClick={() => {
                                if (!programs) {
                                    ref.current.focus()
                                    return
                                }
                                if (invite) {
                                    handleInvite()
                                    return
                                }
                                handleAssign()
                            }}
                        >
                            {invite ? "Invite" : "Add"}
                        </Button>
                    </Box>
                </>
            ) : (
                <Typography variant="subtitle2">
                    You don`t have permission to{" "}
                    {invite
                        ? "invite users to use a program"
                        : "assign this user a program"}
                </Typography>
            )}
        </Box>
    )
}

export default AssignSingleUser
