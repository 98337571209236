import React, { useEffect } from "react"
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { Controller, useForm } from "react-hook-form"

import CustomDateTimePicker from "../../Component/cutomDateTimePicker.jsx"
import usePartialUpdateEntity from "../../Hooks/usePartialUpdateEntity.js"

// Update Voucher Component
function UpdateVoucher({ handleClose, open, refetch, voucherData }) {
    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        mode: "onChange",
    })

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERIES $ MUTATIONS
    const {
        mutate,
        isLoading,
        data: voucher,
        error,
    } = usePartialUpdateEntity(`voucher`)

    // HELPERS & EVENT HANDLERS
    const onSubmit = (formData) => {
        mutate({ ...formData, id: voucherData.id })
    }

    // USEFFECTS
    useEffect(() => {
        if (voucher) {
            enqueueSnackbar(`Succesfully updated.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            handleClose()
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voucher])

    useEffect(() => {
        if (error) {
            const fieldError =
                error.response?.data?.error?.field_error?.[0]?.description
            const errorMessage = error.response?.data?.error?.message

            enqueueSnackbar(fieldError || errorMessage || "Request Failed", {
                variant: "error",
            })
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])
    useEffect(() => {
        if (voucherData) {
            Object.keys(voucherData).forEach((key) =>
                setValue(key, voucherData[key])
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voucherData])
    // RENDER
    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Update Voucher
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        px: 2,
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                        }}
                    >
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: "Name is required",
                            }}
                            render={({ field }) => (
                                <TextField
                                    variant="outlined"
                                    label="Name"
                                    autoComplete="name"
                                    margin="normal"
                                    fullWidth
                                    error={!!errors?.name}
                                    helperText={
                                        errors?.name
                                            ? errors.name.message
                                            : null
                                    }
                                    size="small"
                                    sx={{
                                        mb: 1,
                                    }}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="amount"
                            control={control}
                            rules={{
                                required: "Amount is required",
                                min: 0.1,
                            }}
                            render={({ field }) => (
                                <TextField
                                    variant="outlined"
                                    label="Amount"
                                    autoComplete="amount"
                                    margin="normal"
                                    type="number"
                                    disabled
                                    size="small"
                                    sx={{
                                        mb: 1,
                                    }}
                                    {...field}
                                    onChange={(e) =>
                                        field.onChange(
                                            parseFloat(e.target.value)
                                        )
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="user_limit"
                            control={control}
                            rules={{
                                required: "User Limit is required",
                            }}
                            render={({ field }) => (
                                <TextField
                                    variant="outlined"
                                    label="User Limit"
                                    autoComplete="user_limit"
                                    margin="normal"
                                    type="number"
                                    fullWidth
                                    error={!!errors?.user_limit}
                                    helperText={
                                        errors?.user_limit
                                            ? errors.user_limit.message
                                            : null
                                    }
                                    size="small"
                                    sx={{
                                        mb: 1,
                                    }}
                                    {...field}
                                    onChange={(e) =>
                                        field.onChange(
                                            parseInt(e.target.value, 10)
                                        )
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="expiry_date"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <CustomDateTimePicker
                                    label="Expire Date"
                                    onChange={onChange}
                                    value={value}
                                    date2={DateTime.now().toISO()}
                                    margin="normal"
                                />
                            )}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mb: 1,
                    pr: 4,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        color: "primary.main",
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleClose}
                >
                    <Typography>Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "primary.main",
                        ":hover": { bgcolor: "primary.main" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    <Typography>Update</Typography>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "common.main",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default UpdateVoucher
