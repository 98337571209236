import { useContext, useEffect } from "react";
import { axiosPrivate } from "../Utils/Api/axios.js";
import useRefreshToken from "./useRefreshToken.js";
import AuthContext from "../Context/auth_provider.jsx";

const useAxiosPrivate = () => {
    const { auth } = useContext(AuthContext);
    const refresh = useRefreshToken();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers.Authorization) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `Bearer ${auth?.access_token}`;
                }
                return config
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [refresh, auth])

    return axiosPrivate;
}

export default useAxiosPrivate;
