import { ExportToCsv } from "export-to-csv"

const handleClientExport = (rows) => {
    const csvOptions = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: columns.map((c) => c.header),
    }

    const csvExporter = new ExportToCsv(csvOptions)

    csvExporter.generateCsv(rows.map((row) => row.original))
}

export default handleClientExport
