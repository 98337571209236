import { find, isEqual } from "lodash"
import { useSnackbar } from "notistack"
import { useContext, useRef } from "react"
import { useMutation } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"
import useFetchEntities from "./useFetchEntity.js"

const useAssignPrograms = () => {
    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)
    const companyPrograms = useFetchEntities("programs")

    const assign = useRef([])

    const { mutate, isLoading } = useMutation((data) =>
        axiosPrivate.post(`corporate/${companyId}/programs/assign`, {
            program_users: data,
        })
    )

    const handleAssign = (value) => {
        // BACKEND DOESN'T ACCEPT PROGRAM NAME, ONLY PROGRAM ID
        const programIds = value.programs
            ?.split(",")
            ?.map((e) => e.trim())
            ?.map(
                (prog) =>
                    companyPrograms?.data?.data?.data?.filter(
                        (ele) => ele.name === prog
                    )[0].id
            )
        // BACKEND DOESN'T ACCEPT AN ARRAY OF VALUES FOR PROGRAMIDS SO, FLATTEN THE ARRAY
        programIds?.forEach((id) => {
            const val = {
                user_id: value.userId,
                program_id: id,
                guest: value.guest,
            }

            if (find(assign.current, (el) => isEqual(el, val))) return
            assign.current = [...assign.current, val]
        })
    }

    return {
        assignUser: handleAssign,
        isLoading,
        requestAssign: (callback) => {
            if (assign.current.length === 0) {
                enqueueSnackbar(
                    "None of the people are on ridePlus, make sure to invite them",
                    {
                        variant: "warning",
                    }
                )
                return
            }
            mutate(assign.current, {
                onSuccess: () => {
                    enqueueSnackbar("Program Assigned Successfully", {
                        variant: "success",
                    })
                    callback()
                },
                onError: (error) => {
                    const fieldError =
                        error.response?.data?.error?.field_error[0]?.description
                    const errorMessage = error.response?.data?.error?.message
                    enqueueSnackbar(
                        fieldError || errorMessage || "Request Failed",
                        { variant: "error" }
                    )
                },
            })
        },
        clearAssign: () => {
            assign.current = []
        },
    }
}

export default useAssignPrograms
