import { Container } from "@mui/material"
import React from "react"

function NotFound() {
    return (
        <Container sx={{ textAlign: "center", pt: "5em" }}>
            Page Not Found
        </Container>
    )
}

export default NotFound
