import React, { useContext, useEffect, useMemo, useState } from "react"
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
} from "@mui/material"
import { useMutation, useQueryClient } from "react-query"
import { useSnackbar } from "notistack"
import useFetchEntities from "../Hooks/useFetchEntity.js"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"
import AuthContext from "../Context/auth_provider.jsx"
import useBreakPoints from "../Hooks/useBreakPoints.js"

function UpdatePrograms({ user, open, setOpen, invited }) {
    const { user_id: id, programs, phone } = user || {}

    const { xs, md } = useBreakPoints()

    const queryClient = useQueryClient()

    const [progs, setProgs] = useState([])
    // const userId = useRef(userid)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (programs) {
            const assignedProgs = programs?.map((el) => ({
                name: el.name,
                id: el.id,
            }))
            setProgs(assignedProgs)
        }
    }, [programs, open])

    const { data: companyPrograms } = useFetchEntities("programs")

    // STORE MAPPING OF PROGAM NAME AND ID OF THE COMPANY PROGRAMS
    const progNameId = useMemo(
        () =>
            companyPrograms?.data?.data?.map((prgms) => ({
                name: prgms.name,
                id: prgms.id,
            })),
        [companyPrograms]
    )

    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)

    const { mutate, isLoading } = useMutation(
        (data) =>
            axiosPrivate
                .patch(`corporate/${companyId}/users/${id}/programs`, data)
                .then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [companyId, "users"],
                })
                enqueueSnackbar("updated successfully", {
                    variant: "success",
                })
                setOpen(false)
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )

    const { mutate: updateInvited, isLoading: loadingInvited } = useMutation(
        (data) =>
            axiosPrivate
                .patch(`corporate/${companyId}/users/invite/${phone}`, data)
                .then((res) => res?.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [companyId, "users/invite"],
                })
                enqueueSnackbar("updated successfully", {
                    variant: "success",
                })
                setOpen(false)
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )

    const handleChange = (e, val) => setProgs(val)

    const handleUpdate = () => {
        if (invited) {
            updateInvited({
                program_id: progs.map((prog) => prog.id),
            })
        } else {
            mutate({
                user_id: id,
                program_id: progs.map((prog) => prog.id),
            })
        }
    }

    return (
        <Dialog
            fullScreen={xs && !md}
            maxWidth="md"
            PaperProps={{
                sx: {
                    minWidth: md && "70ch",
                },
            }}
            onClose={() => setOpen(false)}
            open={open}
        >
            <DialogTitle
                sx={{
                    margin: "auto",
                    mb: "1em",
                    textAlign: "center",
                    backgroundColor: "common.main",
                    width: "100%",
                }}
            >
                Edit Programs
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    p: "1em",
                }}
            >
                <Autocomplete
                    multiple
                    value={progs}
                    getOptionLabel={(option) => option?.name}
                    onChange={handleChange}
                    options={progNameId || []}
                    isOptionEqualToValue={(option, value) =>
                        option.name === value
                    }
                    renderOption={(props, option) => (
                        <Typography variant="subtitle1" {...props}>
                            {option.name}
                        </Typography>
                    )}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                        minWidth: md && "40ch",
                    }}
                />
                <Box sx={{ display: "flex", gap: "1em", pl: "1em" }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ color: "lightgray" }}>
                            Program
                        </Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                            Basic
                        </Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ color: "lightgray" }}>
                            Voucher
                        </Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                            HETNDNSSWWO
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: "1em" }}>
                <Button
                    onClick={() => setOpen(false)}
                    variant="contained"
                    color="common"
                >
                    Cancel
                </Button>
                <Button
                    disabled={progs?.length === 0}
                    startIcon={
                        isLoading || loadingInvited ? (
                            <CircularProgress
                                size="1em"
                                sx={{ color: "white" }}
                            />
                        ) : null
                    }
                    variant="contained"
                    onClick={handleUpdate}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdatePrograms
