import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import MaterialReactTable from "material-react-table"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"

import useTableState from "../../Hooks/useTableState.js"
import useTableProps from "../../Hooks/useTableProps.js"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"

import customeFilter from "../../Component/customFilterPanel.jsx"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"

const discountUserFields = [
    {
        accessorKey: "first_name",
        header: "First Name",
        size: 240,
        Cell: ({ row }) => row?.original?.user_info?.first_name,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "middle_name",
        header: "Middle Name",
        size: 240,
        Cell: ({ row }) => row?.original?.user_info?.middle_name,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "last_name",
        header: "Last Name",
        size: 200,
        Cell: ({ row }) => row?.original?.user_info?.last_name,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "phone",
        header: "Phone Number",
        size: 200,
        Cell: ({ row }) => row?.original?.user_info?.phone,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "amount",
        header: "Amount",
        filterVariant: "number",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "email",
        header: "Email",
        size: 200,
        Cell: ({ row }) => row?.original?.user_info?.email,
        renderColumnFilterModeMenuItems: customeFilter,
    },
]

function DiscountUsers() {
    const { discountUsers } = useUserPermissions()
    const { discountId } = useParams()

    const { md } = useBreakPoints()

    const { params, state, onChangeHandlers } = useTableState({
        columns: discountUserFields,
    })

    const [total, setTotal] = useState(0)

    // TO KEEP THE PAGINATION STATE WHEN API ERRORS OUT
    useEffect(() => {
        setTotal((prevTotal) => (total !== undefined ? total : prevTotal))
    }, [total, setTotal])

    const handleVouchersExport = (data) =>
        data?.map((el) => ({
            ...el,
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            updated_at: DateTime.fromISO(el.updated_at).toFormat("ff"),
            expiry_date: DateTime.fromISO(el.expiry_date).toFormat("ff"),
        }))

    const { filterModel, ...rest } = params
    const { data, isLoading, isFetching } = useFetchEntitiesPerPage(
        {
            endPoint: `discount/${discountId}/users`,
            filterModel: params.filterModel,
            ...rest,
        },
        {
            enabled: discountUsers,
        }
    )

    const { props } = useTableProps({
        columns: discountUserFields,
        exportProps: {
            dataEndPoint: `discount/${discountId}/users`,
            model: params.filterModel,
            formatter: handleVouchersExport,
        },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
    })

    useEffect(() => {
        if (data?.data?.meta_data.total) setTotal(data?.data?.meta_data.total)
    }, [data])

    if (!discountUsers) return <RestrictedAccess />

    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: "white",
                }}
            >
                <MaterialReactTable
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            right: [md ? "status" : null],
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    {...props}
                    {...onChangeHandlers}
                />
            </Box>
        </Box>
    )
}

export default DiscountUsers
