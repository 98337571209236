import React, { useState, useEffect, useContext } from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { Controller, useForm } from "react-hook-form"
import { capitalize, debounce } from "lodash"
import { useNavigate } from "react-router-dom"
import { useMutation } from "react-query"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import {
    CalendarMonth,
    EventBusy,
    SwapCalls,
    Vignette,
    WatchLater,
} from "@mui/icons-material"
import CustomeDatePicker from "../../Component/datePicker.jsx"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import AuthContext from "../../Context/auth_provider.jsx"

const timeRanges = [
    "12-1",
    "1-2",
    "2-3",
    "3-4",
    "4-5",
    "5-6",
    "6-7",
    "7-8",
    "8-9",
    "9-10",
    "10-11",
    "11-12",
]

const weakdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const timeZones = [
    "ETH (LOCAL)",
    "EAT",
    "UTC",
    "GMT",
    "EET",
    "MSK",
    "GST",
    "PKT",
    "BST",
    "ICT",
    "JST",
    "AEST",
    "NZST",
    "IDLW",
    "SST",
    "HST",
    "AKST",
    "PST",
    "MST",
    "CST",
    "EST",
    "AST",
]

function CreateProgram() {
    const {
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
        control,
    } = useForm({
        defaultValues: {
            spending_limit_duration: "daily",
            trip_limit_duration: "daily",
            time_limit_timezon: "ETH (LOCAL)",
            date_limit: false,
            spending_limit: false,
            time_limit: false,
            trip_limit: false,
            has_expire_date: false,
        },
    })

    const [customeWatch, setCustomeWatch] = useState(null)
    const [expandedAccord, setExpandedAccord] = useState(false)
    const [all, setAll] = useState({
        days: false,
        time: false,
    })

    // NONE UI STATES
    const { companyId } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        const subscription = watch((d) => {
            const tripLim = d.trip_limit_amount
            const spendingLim = d.spending_limit_amount

            // TO DEBOUNCE THE WATCH, AVOIDS RE-RENDER ON EVERY KEYSTROKE
            if (tripLim >= 0 || spendingLim >= 0)
                debounce(() => setCustomeWatch(d), 100)()
        })

        return () => subscription.unsubscribe()
    }, [watch])

    // SERVER STATE AND MUTATIONS
    const axiosPrivate = useAxiosPrivate()
    const { enqueueSnackbar } = useSnackbar()

    const { mutate, isLoading } = useMutation(
        (data) =>
            axiosPrivate
                .post(`/corporate/${companyId}/programs`, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => res.data),
        {
            onSuccess: () => {
                enqueueSnackbar("program created successfully", {
                    variant: "success",
                })
                navigate("/programs")
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message

                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )

    // HANDLERS
    const createProgram = (d) => {
        // BACKEND DOESN'T RECEIVE A `NONE DATEtIME` VALUE FOR EXPIRE DATE
        if (!d.expire_date) {
            const D = {
                ...d,
            }
            mutate({
                ...D,
            })
        } else {
            mutate(d)
        }
    }

    return (
        <Container
            maxWidth="false"
            sx={{ pb: { md: "3em", xs: "1em" }, pt: { md: "3em" } }}
        >
            <Grid
                container
                spacing={1}
                justifyContent="space-around"
                sx={{
                    pt: "1.5em",
                    pl: { xs: 0 },
                }}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5em",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5em",
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: "500" }}>
                            Program Name
                        </Typography>
                        <Controller
                            name="name"
                            rules={{ required: "Program name is required" }}
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                                <TextField
                                    value={value}
                                    inputRef={ref}
                                    onChange={(e) =>
                                        onChange(e.currentTarget.value)
                                    }
                                    fullWidth
                                    helperText={errors.name?.message}
                                />
                            )}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5em",
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: "500" }}>
                            Description
                        </Typography>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    onChange={(e) =>
                                        onChange(e.currentTarget.value)
                                    }
                                    multiline
                                    rows={3}
                                    fullWidth
                                />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5em",
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        Rule
                    </Typography>
                    <Accordion
                        expanded={expandedAccord === "trip_limit"}
                        onChange={() => {
                            if (expandedAccord === "trip_limit") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("trip_limit")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <IconButton>
                                    <SwapCalls />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Trip Limit
                                    </Typography>
                                    <Typography variant="body2">
                                        {customeWatch?.trip_limit_amount &&
                                        watch("trip_limit_duration")
                                            ? `${
                                                  customeWatch?.trip_limit_amount
                                              } trips ${watch(
                                                  "trip_limit_duration"
                                              )}`
                                            : "No Limit"}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography
                                color="secondary"
                                sx={{ alignSelf: "center", fontWeight: "bold" }}
                            >
                                Edit
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                Enter limit amount
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="trip_limit_amount"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <TextField
                                                fullWidth
                                                value={value || ""}
                                                onChange={(e) => {
                                                    const val =
                                                        e.currentTarget.value
                                                    onChange(Number(val))
                                                    if (val > 0) {
                                                        setValue(
                                                            "trip_limit",
                                                            true
                                                        )
                                                    } else
                                                        setValue(
                                                            "trip_limit",
                                                            false
                                                        )
                                                }}
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        min: 1,
                                                        sx: {
                                                            backgroundColor:
                                                                "white",
                                                            mr: ".5em",
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="trip_limit_duration"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Autocomplete
                                                value={capitalize(value)}
                                                onChange={(e, newValue) => {
                                                    onChange(
                                                        newValue.toLowerCase()
                                                    )
                                                }}
                                                options={[
                                                    "Daily",
                                                    "Monthly",
                                                    "Weekly",
                                                ]}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="trip limit duration"
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccord === "date_limit"}
                        onChange={() => {
                            if (expandedAccord === "date_limit") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("date_limit")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <IconButton>
                                    <CalendarMonth />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Date Limit
                                    </Typography>
                                    <Typography variant="body2">
                                        {watch("date_limit_weekdays")?.length &&
                                        !all.days
                                            ? watch("date_limit_weekdays")?.map(
                                                  (index) => weakdays[index]
                                              )
                                            : "No Limit"}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography
                                color="secondary"
                                sx={{ alignSelf: "center", fontWeight: "bold" }}
                            >
                                Edit
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="subtitle2">
                                Day of the weak
                            </Typography>
                            <Controller
                                name="date_limit_weekdays"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButtonGroup
                                        value={value}
                                        onChange={(e, updatedValue) => {
                                            onChange(updatedValue)
                                            if (updatedValue) {
                                                if (
                                                    updatedValue?.length !==
                                                        7 &&
                                                    updatedValue?.length > 0
                                                ) {
                                                    setValue("date_limit", true)
                                                    setAll({
                                                        ...all,
                                                        days: false,
                                                    })
                                                } else {
                                                    setAll({
                                                        ...all,
                                                        days: true,
                                                    })
                                                    setValue(
                                                        "date_limit",
                                                        false
                                                    )
                                                }
                                            }
                                        }}
                                        sx={{
                                            gap: ".3em",
                                            flexWrap: "wrap",
                                            "&.MuiToggleButtonGroup-grouped": {
                                                width: "7ch",
                                            },
                                        }}
                                    >
                                        {weakdays.map((ele, index) => (
                                            <ToggleButton
                                                key={ele}
                                                value={index}
                                            >
                                                {ele}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                )}
                            />
                            <Controller
                                name="date_limit_weekdays"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <FormControlLabel
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                onChange([0, 1, 2, 3, 4, 5, 6])
                                                setValue("date_limit", false)
                                                setAll({ ...all, days: true })
                                            } else {
                                                onChange([])
                                                setValue("date_limit", false)
                                                setAll({ ...all, days: false })
                                            }
                                        }}
                                        checked={all.days}
                                        control={<Checkbox />}
                                        label="Every Day"
                                    />
                                )}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccord === "spending_limit"}
                        onChange={() => {
                            if (expandedAccord === "spending_limit") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("spending_limit")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                }}
                            >
                                <IconButton>
                                    <Vignette />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Spending Limit
                                    </Typography>
                                    <Typography variant="body2">
                                        {customeWatch?.spending_limit_amount &&
                                        watch("spending_limit_duration")
                                            ? `${
                                                  customeWatch?.spending_limit_amount
                                              } ETB ${watch(
                                                  "spending_limit_duration"
                                              )}`
                                            : "No Limit"}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography
                                color="secondary"
                                sx={{ fontWeight: "bold", alignSelf: "center" }}
                            >
                                Edit
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="subtitle2">
                                Enter limit amount
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="spending_limit_amount"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <TextField
                                                fullWidth
                                                value={value || ""}
                                                onChange={(e) => {
                                                    const val =
                                                        e.currentTarget.value
                                                    onChange(Number(val))
                                                    if (val > 0) {
                                                        setValue(
                                                            "spending_limit",
                                                            true
                                                        )
                                                    } else
                                                        setValue(
                                                            "spending_limit",
                                                            false
                                                        )
                                                }}
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        min: 200,
                                                        sx: {
                                                            backgroundColor:
                                                                "white",
                                                            mr: ".5em",
                                                        },
                                                    },
                                                    sx: {
                                                        "&.MuiInputBase-root": {
                                                            pr: "0.5em",
                                                            backgroundColor:
                                                                "#dfdfdf",
                                                        },
                                                    },
                                                    endAdornment: (
                                                        <Typography
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            ETB
                                                        </Typography>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="spending_limit_duration"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Autocomplete
                                                value={capitalize(value)}
                                                onChange={(e, newValue) =>
                                                    onChange(
                                                        newValue.toLowerCase()
                                                    )
                                                }
                                                options={[
                                                    "Daily",
                                                    "Monthly",
                                                    "Weekly",
                                                ]}
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccord === "time_limit"}
                        onChange={() => {
                            if (expandedAccord === "time_limit") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("time_limit")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <IconButton>
                                    <WatchLater />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Time Limit
                                    </Typography>
                                    <Typography variant="body2">
                                        {watch("time_limit_times")?.length &&
                                        !all.time
                                            ? watch("time_limit_times")?.map(
                                                  (ele) => {
                                                      if (ele > 12) {
                                                          return `${
                                                              timeRanges[
                                                                  ele - 13
                                                              ]
                                                          } ማታ, `
                                                      }
                                                      return `${
                                                          timeRanges[ele - 1]
                                                      } ቀን, `
                                                  }
                                              )
                                            : "No Time Limit"}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography
                                color="secondary"
                                sx={{ fontWeight: "bold", alignSelf: "center" }}
                            >
                                Edit
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-around"
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Time zone
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="time_limit_timezon"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Autocomplete
                                                value={value}
                                                onChange={(e, newValue) => {
                                                    if (
                                                        newValue !==
                                                        (undefined || null)
                                                    )
                                                        onChange(newValue)
                                                }}
                                                options={timeZones}
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                mt: "1.5em",
                                                mb: ".8em",
                                                backgroundColor: "common.main",
                                                textAlign: "center",
                                            }}
                                        >
                                            ቀን
                                        </Typography>
                                        <Controller
                                            name="time_limit_times"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <ToggleButtonGroup
                                                    value={value}
                                                    onChange={(
                                                        e,
                                                        updatedValue
                                                    ) => {
                                                        onChange(updatedValue)
                                                        if (updatedValue) {
                                                            if (
                                                                updatedValue?.length !==
                                                                    24 &&
                                                                updatedValue?.length >
                                                                    0
                                                            ) {
                                                                setValue(
                                                                    "time_limit",
                                                                    true
                                                                )
                                                                setAll({
                                                                    ...all,
                                                                    time: false,
                                                                })
                                                            } else {
                                                                setAll({
                                                                    ...all,
                                                                    time: true,
                                                                })
                                                                setValue(
                                                                    "time_limit",
                                                                    false
                                                                )
                                                            }
                                                        }
                                                    }}
                                                    sx={{
                                                        gap: ".5em",
                                                        flexWrap: "wrap",
                                                        "&.MuiToggleButtonGroup-grouped":
                                                            {
                                                                width: "7ch",
                                                            },
                                                    }}
                                                >
                                                    {timeRanges.map(
                                                        (range, index) => (
                                                            <ToggleButton
                                                                key={range}
                                                                value={
                                                                    index + 1
                                                                }
                                                            >
                                                                {range}
                                                            </ToggleButton>
                                                        )
                                                    )}
                                                </ToggleButtonGroup>
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                mt: "1.5em",
                                                mb: ".8em",
                                                backgroundColor: "common.main",
                                                textAlign: "center",
                                            }}
                                        >
                                            ማታ
                                        </Typography>
                                        <Controller
                                            name="time_limit_times"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <ToggleButtonGroup
                                                    value={value}
                                                    onChange={(
                                                        e,
                                                        updatedValue
                                                    ) => {
                                                        onChange(updatedValue)
                                                        if (updatedValue) {
                                                            setValue(
                                                                "time_limit",
                                                                true
                                                            )
                                                            if (
                                                                updatedValue?.length !==
                                                                24
                                                            ) {
                                                                setAll({
                                                                    ...all,
                                                                    time: false,
                                                                })
                                                            } else {
                                                                setAll({
                                                                    ...all,
                                                                    time: true,
                                                                })
                                                                setValue(
                                                                    "time_limit",
                                                                    false
                                                                )
                                                            }
                                                        }
                                                    }}
                                                    sx={{
                                                        gap: ".5em",
                                                        flexWrap: "wrap",
                                                        "&.MuiToggleButtonGroup-grouped":
                                                            {
                                                                width: "7ch",
                                                            },
                                                    }}
                                                >
                                                    {timeRanges.map(
                                                        (range, index) => (
                                                            <ToggleButton
                                                                key={range}
                                                                value={
                                                                    index + 13
                                                                }
                                                            >
                                                                {range}
                                                            </ToggleButton>
                                                        )
                                                    )}
                                                </ToggleButtonGroup>
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Controller
                                name="time_limit_times"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <FormControlLabel
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                onChange(
                                                    [...Array(24).keys()].map(
                                                        (ele) => ele + 1
                                                    )
                                                )
                                                setValue("time_limit", false)
                                                setAll({ ...all, time: true })
                                            } else {
                                                onChange([])
                                                setValue("time_limit", false)
                                                setAll({ ...all, time: false })
                                            }
                                        }}
                                        checked={all.time}
                                        control={<Checkbox />}
                                        label="All Day"
                                    />
                                )}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccord === "expire_date"}
                        onChange={() => {
                            if (expandedAccord === "expire_date") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("expire_date")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <IconButton>
                                    <EventBusy />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Expire Date
                                    </Typography>
                                    <Typography variant="body2">
                                        {watch("expire_date")
                                            ? DateTime.fromISO(
                                                  watch("expire_date")
                                              ).toFormat("f")
                                            : "No Expire Date"}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography
                                color="secondary"
                                sx={{ fontWeight: "bold", alignSelf: "center" }}
                            >
                                Edit
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                Enter Expire Date
                            </Typography>
                            <Controller
                                name="expire_date"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <CustomeDatePicker
                                        value={value}
                                        onChange={onChange}
                                        minDate={DateTime.now().toISO()}
                                    />
                                )}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sm={12} md={11}>
                    <Divider sx={{ mx: "auto", my: ".5em", mt: "1.5em" }} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} />
                <Grid item xs={12} sm={12} md={6}>
                    <Box
                        sx={{
                            display: "flex",
                            margin: "auto",
                        }}
                    >
                        <Button
                            color="common"
                            variant="contained"
                            onClick={() => navigate("/programs")}
                            sx={{
                                marginLeft: "auto",
                                marginRight: "1em",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            startIcon={
                                isLoading ? (
                                    <CircularProgress
                                        size="1em"
                                        sx={{ color: "white" }}
                                    />
                                ) : null
                            }
                            variant="contained"
                            onClick={handleSubmit(createProgram)}
                        >
                            Add
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CreateProgram
