import { createTheme } from "@mui/material"

const theme = createTheme({
    palette: {
        primary: {
            main: "#000000",
        },
        secondary: {
            main: "#2cc84b",
        },
        common: {
            main: "#f5f5f5",
        },
    },
    status: {
        danger: "#D41E1E",
    },
    components: {
        MuiButton: {
            defaultProps: {
                color: "primary",
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
            styleOverrides: {
                root: {
                    border: "none",
                },
            },
        },
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    "&.Mui-expanded": {
                        boxShadow: "none",
                    },
                    "&:before": {
                        display: "none",
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: "#f5f5f5",
                    "&.Mui-expanded": {
                        outline: "1px solid yellow",
                        boxShadow: "-1px 1px 3px lightgray",
                        backgroundColor: "white",
                        marginBottom: ".5em",
                    },
                },
            },
        },
        MuiIconButton: {
            variants: [
                {
                    props: { variant: "grid" },
                    style: {
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: 4,
                        width: "30px",
                        height: "30px",
                        "&:hover": {
                            backgroundColor: "black",
                            outline: "2px solid #33ca51",
                            color: "white",
                        },
                    },
                },
            ],
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#f5f5f5",
                    "&.Mui-selected": {
                        border: "2px solid yellow",
                        backgroundColor: "white",
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    padding: 0,
                    paddingLeft: 6,
                    paddingRight: 0,
                    marginLeft: 0,
                    marginRight: 0,
                },
                icon: {
                    marginRight: 4,
                },
                message: {
                    marginLeft: 2,
                    marginRight: 4,
                    paddingRight: 8,
                },
                action: {
                    paddingLeft: 0,
                    marginRight: 2,
                    marginLeft: 0,
                },
            },
        },
    },
})
export default theme
