import * as React from "react";

function useAddToHomescreenPrompt(){
  const [prompt, setState] = React.useState(
    null
  );

  const userChoice = window.localStorage.getItem('desktopIcon')

  const promptToInstall = () => {
    if (prompt && (userChoice !== 'NO')) {
      return prompt.prompt();
    }
    return null
  };

  React.useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      if(userChoice !== 'NO') {
        setState(e);
      }
    };

    window.addEventListener("beforeinstallprompt", ready);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, [userChoice]);

  return [prompt,setState, promptToInstall];
}

export default useAddToHomescreenPrompt