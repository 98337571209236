import { merge } from "lodash"
import { useState } from "react"
import filterModel from "../Utils/filterModel.js"

const useTableState = ({ columns }) => {
    const defaultFilterValues = columns.map((el) => {
        switch (el.filterVariant) {
            case "number":
                return { [el.accessorKey || el.id]: "=" }
            case "date":
                return { [el.accessorKey || el.id]: "is" }
            case "datetime-local":
                return { [el.accessorKey || el.id]: "is" }
            case "boolean":
                return { [el.accessorKey || el.id]: "is" }
            case "json":
                return { [el.accessorKey || el.id]: "contains" }
            case "select":
                return { [el.accessorKey || el.id]: "is" }
            case "multi-select":
                return { [el.accessorKey || el.id]: "is" }
            case "string":
            default:
                return { [el.accessorKey || el.id]: "contains" }
        }
    })

    const initialState = merge(...defaultFilterValues)

    // TABLE STATE
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState(initialState)
    const [globalFilter, setGlobalFilter] = useState("")
    const [sorting, setSorting] = useState([])
    const [density, setDesity] = useState('compact')
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })

    // THE FILTER MODEL
    const model = filterModel(filterFns, columnFilters, columns)

    // PROPS TO THE TABLE COMPONENT
    const props = {
        onColumnFiltersChange: setColumnFilters,
        onColumnFilterFnsChange: setFilterFns,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onDensityChange: setDesity,
        muiTableHeadCellFilterTextFieldProps: ({ column }) => ({
            helperText: `Filter Mode: ${filterFns[column.id]}`,
        }),
    }

    const state = {
        columnFilters,
        globalFilter,
        columnFilterFns: filterFns,
        pagination,
        sorting,
        density
    }

    return {
        onChangeHandlers: props,
        state,
        density,
        params: {
            page: pagination.pageIndex,
            perPage: pagination.pageSize,
            sortModel: sorting?.map((el) => ({
                field: el.id,
                sort: el.desc ? "DESC" : "ASC",
            })),
            filterModel: model,
            searchText: globalFilter,
            linkOperator: "and",
        },
    }
}

export default useTableState
