import { useContext } from "react"
import { useMutation } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"

const useSSOUsers = () => {
    const { companyId } = useContext(AuthContext)
    const axiosPrivate = useAxiosPrivate()

    return useMutation((phone) =>
        axiosPrivate
            .post(`corporate/${companyId}/users/phone`, phone)
            .then((res) => res.data)
    )
}

export default useSSOUsers
