import React, { useEffect, useState } from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import {
    CalendarMonth,
    EventBusy,
    SwapCalls,
    Vignette,
    WatchLater,
} from "@mui/icons-material"
import { useLocation } from "react-router-dom"
import { DateTime } from "luxon"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import ActivityLog from "../../Component/activityLog.jsx"

const timeRanges = [
    "12-1",
    "1-2",
    "2-3",
    "3-4",
    "4-5",
    "5-6",
    "6-7",
    "7-8",
    "8-9",
    "9-10",
    "10-11",
    "11-12",
]

const weakdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

function ProgramDetails() {
    const { programsDetail, activitiesList } = useUserPermissions()
    const [expandedAccord, setExpandedAccord] = useState(false)

    const [all, setAll] = useState({
        days: false,
        time: false,
    })

    const location = useLocation()

    // ACCESS THE STATE PROP OF THE LINK OBJECT
    const value = location.state

    // CHANGE THE STATUS OF THE CHECKBOX, AND RENDER `NO LIMIT` WHEN ALL ARE SELECTED
    useEffect(() => {
        if (value.time_limit_times?.length === 24 || value.time_limit === false)
            setAll((prev) => ({ ...prev, time: true }))
        if (
            value.date_limit_weekdays?.length === 7 ||
            value.date_limit === false
        )
            setAll((prev) => ({ ...prev, days: true }))
    }, [value])

    if (!programsDetail) return <RestrictedAccess />

    return (
        <Container
            maxWidth="false"
            sx={{
                pb: { md: "5em", xs: "1em" },
                pt: { md: "3em" },
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
            }}
        >
            <Grid
                container
                spacing={1}
                justifyContent="space-around"
                sx={{
                    flex: 2,
                    pt: "1.5em",
                    pl: { xs: 0 },
                }}
            >
                <Grid
                    item
                    xs={12}
                    lg={4}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5em",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5em",
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: "500" }}>
                            Program Name
                        </Typography>
                        <TextField
                            value={value.name}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5em",
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: "500" }}>
                            Description
                        </Typography>
                        <TextField
                            value={value.description}
                            multiline
                            InputProps={{
                                readOnly: true,
                            }}
                            rows={3}
                            fullWidth
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5em",
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        Rule
                    </Typography>
                    <Accordion
                        expanded={expandedAccord === "trip_limit"}
                        onChange={() => {
                            if (expandedAccord === "trip_limit") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("trip_limit")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <IconButton>
                                    <SwapCalls />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Trip Limit
                                    </Typography>
                                    <Typography>
                                        {(() => {
                                            const isUnlimited =
                                                value.trip_limit_amount === 0 ||
                                                value.trip_limit === false
                                            if (isUnlimited) return "No Limit"
                                            return `${value.trip_limit_amount} trips ${value.trip_limit_duration}`
                                        })()}
                                    </Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                trip limit amount
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={
                                            value.trip_limit_amount === 0
                                                ? ""
                                                : value.trip_limit_amount
                                        }
                                        type="number"
                                        InputProps={{
                                            readOnly: true,
                                            inputProps: {
                                                sx: {
                                                    backgroundColor: "white",
                                                    mr: ".5em",
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={value.trip_limit_duration}
                                        label="trip limit duration"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccord === "date_limit"}
                        onChange={() => {
                            if (expandedAccord === "date_limit") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("date_limit")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <IconButton>
                                    <CalendarMonth />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Date Limit
                                    </Typography>
                                    <Typography>
                                        {!all.days &&
                                        value?.date_limit_weekdays?.length > 0
                                            ? value.date_limit_weekdays?.map(
                                                  (ele) =>
                                                      `${weakdays[ele - 1]}, `
                                              )
                                            : "No Day Limit"}
                                    </Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="subtitle2">
                                Day of the weak
                            </Typography>
                            <ToggleButtonGroup
                                value={value.date_limit_weekdays}
                                sx={{
                                    gap: ".3em",
                                    flexWrap: "wrap",
                                    "&.MuiToggleButtonGroup-grouped": {
                                        width: "7ch",
                                    },
                                }}
                            >
                                {weakdays.map((ele, index) => (
                                    <ToggleButton key={ele} value={index + 1}>
                                        {ele}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                            <FormControlLabel
                                checked={all.days}
                                control={<Checkbox />}
                                label="Every Day"
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccord === "spending_limit"}
                        onChange={() => {
                            if (expandedAccord === "spending_limit") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("spending_limit")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                }}
                            >
                                <IconButton>
                                    <Vignette />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Spending Limit
                                    </Typography>
                                    <Typography>
                                        {(() => {
                                            const isUnlimited =
                                                !value.spending_limit
                                            return isUnlimited ? (
                                                <Typography>
                                                    No Limit
                                                </Typography>
                                            ) : (
                                                `${value.spending_limit_amount} ETB ${value.spending_limit_duration}`
                                            )
                                        })()}
                                    </Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="subtitle2">
                                spending limit amount
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={
                                            value.spending_limit_amount === "0"
                                                ? ""
                                                : value.spending_limit_amount
                                        }
                                        type="number"
                                        InputProps={{
                                            readOnly: true,
                                            inputProps: {
                                                sx: {
                                                    backgroundColor: "white",
                                                    mr: ".5em",
                                                },
                                            },
                                            sx: {
                                                "&.MuiInputBase-root": {
                                                    pr: "0.5em",
                                                    backgroundColor: "#dfdfdf",
                                                },
                                            },
                                            endAdornment: (
                                                <Typography
                                                    sx={{
                                                        backgroundColor:
                                                            "#dfdfdf",
                                                    }}
                                                >
                                                    ETB
                                                </Typography>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={value.spending_limit_duration}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccord === "time_limit"}
                        onChange={() => {
                            if (expandedAccord === "time_limit") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("time_limit")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <IconButton>
                                    <WatchLater />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Time Limit
                                    </Typography>
                                    <Typography>
                                        {!all.time
                                            ? value.time_limit_times?.map(
                                                  (time) => {
                                                      if (time === 1)
                                                          return `12-1 ቀን,`
                                                      if (time <= 12)
                                                          return `${
                                                              time - 1
                                                          }-${time} ቀን,`
                                                      if (time === 13)
                                                          return `12-1 ማታ,`
                                                      return `${time - 13}-${
                                                          time - 12
                                                      } ማታ,`
                                                  }
                                              )
                                            : "No Time Limit "}
                                    </Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-around"
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Time zone
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={value.time_limit_timezon}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                mt: "1.5em",
                                                mb: ".8em",
                                                backgroundColor: "common.main",
                                                textAlign: "center",
                                            }}
                                        >
                                            ቀን
                                        </Typography>
                                        <ToggleButtonGroup
                                            value={value.time_limit_times}
                                            sx={{
                                                gap: ".5em",
                                                flexWrap: "wrap",
                                                "&.MuiToggleButtonGroup-grouped":
                                                    {
                                                        width: "7ch",
                                                    },
                                            }}
                                        >
                                            {timeRanges.map((range, index) => (
                                                <ToggleButton
                                                    key={range}
                                                    value={index + 1}
                                                >
                                                    {range}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                mt: "1.5em",
                                                mb: ".8em",
                                                backgroundColor: "common.main",
                                                textAlign: "center",
                                            }}
                                        >
                                            ማታ
                                        </Typography>
                                        <ToggleButtonGroup
                                            value={value.time_limit_times}
                                            sx={{
                                                gap: ".5em",
                                                flexWrap: "wrap",
                                                "&.MuiToggleButtonGroup-grouped":
                                                    {
                                                        width: "7ch",
                                                    },
                                            }}
                                        >
                                            {timeRanges.map((range, index) => (
                                                <ToggleButton
                                                    key={range}
                                                    value={index + 13}
                                                >
                                                    {range}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </Box>
                                </Grid>
                            </Grid>
                            <FormControlLabel
                                checked={all.time}
                                control={<Checkbox />}
                                label="All Day"
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedAccord === "expire_date"}
                        onChange={() => {
                            if (expandedAccord === "expire_date") {
                                setExpandedAccord(false)
                            } else {
                                setExpandedAccord("expire_date")
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <GridExpandMoreIcon color="secondary" />
                            }
                        >
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <IconButton>
                                    <EventBusy />
                                </IconButton>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Expire Date
                                    </Typography>
                                    <Typography>
                                        {(() => {
                                            const isUnlimited =
                                                value.has_expire_date === false
                                            return isUnlimited ? (
                                                <Typography>
                                                    No Expire Date
                                                </Typography>
                                            ) : (
                                                DateTime.fromISO(
                                                    value.expire_date
                                                ).toFormat("ff")
                                            )
                                        })()}
                                    </Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">expire date</Typography>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={
                                    value?.expire_date !==
                                    "0001-01-01T00:00:00Z"
                                        ? DateTime.fromISO(
                                              value.expire_date
                                          ).toFormat("ff")
                                        : null
                                }
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ width: "10px", color: "gray" }}
            />
            {activitiesList && (
                <Grid sx={{ flex: 1.5, pt: "1.2em" }}>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", pb: "0px", pl: "16px" }}
                    >
                        Activity Log
                    </Typography>
                    <Divider variant="middle" sx={{ pb: "1em" }} />
                    <ActivityLog url={`programs/${value?.id}/activities`} />
                </Grid>
            )}
        </Container>
    )
}

export default ProgramDetails
