import React from "react"
import { useLocation } from "react-router-dom"
import {
    Autocomplete,
    Avatar,
    Box,
    Divider,
    TextField,
    Typography,
} from "@mui/material"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import {
    SSO_ASSETS_URL,
    SSO_BASE_URL,
    SSO_VERSION,
} from "../../Utils/config.js"
import ActivityLog from "../../Component/activityLog.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"

function User({ name, phone, src }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "common.main",
                py: "1em",
                px: "1em",
            }}
        >
            <Avatar
                style={{
                    height: "3em",
                    width: "3em",
                    borderRadius: "50%",
                    objectFit: "contain",
                }}
                src={src || " "}
            />
            <Typography
                variant="body1"
                sx={{ fontSize: "1.2em", textAlign: "center" }}
            >
                {name}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "1.2em" }}>
                +{phone}
            </Typography>
        </Box>
    )
}

function ViewSystemUser() {
    const { activitiesList } = useUserPermissions()
    const { state } = useLocation()

    const {
        first_name: fname,
        last_name: lname,
        middle_name: mname,
        phone,
        id,
        profile_picture: src,
        roles: userRoles,
    } = state || {}

    const { xs, md } = useBreakPoints()

    return (
        <Box
            fullScreen={xs && !md}
            sx={{
                p: "2em",
                display: "flex",
                gap: "2em",
                flexDirection: { xs: "column", md: "row" },
            }}
        >
            <Box sx={{ flex: 2 }}>
                <Box
                    sx={{
                        textAlign: "center",
                        backgroundColor: "#fafafa",
                        mb: "1em",
                        p: "1.5em",
                        fontWeight: 550,
                        letterSpacing: ".01em",
                        wordSpacing: ".2em",
                    }}
                >
                    User Roles
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1em",
                    }}
                >
                    <User
                        name={`${fname} ${mname} ${lname}`}
                        phone={phone}
                        src={`${SSO_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${src}`}
                    />
                    <Autocomplete
                        readOnly
                        multiple
                        value={userRoles?.length ? userRoles : [userRoles]}
                        options={[]}
                        getOptionLabel={(opt) => opt?.role_name}
                        renderInput={(params) => (
                            <TextField label="Roles" {...params} />
                        )}
                        sx={{
                            ".MuiAutocomplete-endAdornment": {
                                display: "none",
                            },
                        }}
                    />
                </Box>
            </Box>
            {activitiesList && (
                <Box sx={{ flex: 3 }}>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", pb: "0px", pl: "16px" }}
                    >
                        Activity Log
                    </Typography>
                    <Divider variant="middle" sx={{ pb: "1em" }} />
                    <ActivityLog
                        url="activities"
                        filter={[
                            {
                                column_field: "user_id",
                                operator_value: "contains",
                                value: id,
                            },
                        ]}
                    />
                </Box>
            )}
        </Box>
    )
}

export default ViewSystemUser
