import { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"

// Update Entity Hook
/* 
    Modifying entity where the client sends data that updates the entire resource.
*/
const useUpdateEntity = (page) => {
    const queryClient = useQueryClient()
    const { companyId } = useContext(AuthContext)

    const axiosPrivate = useAxiosPrivate()

    return useMutation(
        (data) =>
            axiosPrivate
                .put(`/corporate/${companyId}/${page}`, data)
                .then((response) => response),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([page])
            },
        }
    )
}

export default useUpdateEntity
