import React, { useCallback, useMemo, useState } from "react"
import { DateTime } from "luxon"
import { useLocation } from "react-router-dom"
import { Box, Button, IconButton } from "@mui/material"
import MaterialReactTable from "material-react-table"
import { Visibility } from "@mui/icons-material"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import customeFilter from "../../Component/customFilterPanel.jsx"
import useTableState from "../../Hooks/useTableState.js"
import useTableProps from "../../Hooks/useTableProps.js"
import ViewTripDetails from "../../Component/viewTripDetail.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import { formatToTwoDecimalPlaces } from "../../Utils/dataFormat.js"

const transactions = [
    {
        accessorKey: "passenger_name",
        header: "Name",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "passenger_phone",
        header: "Phone",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "driver_name",
        header: "Driver Name",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "driver_phone",
        header: "Driver Phone",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "amount",
        header: "Amount",
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
        Cell: ({ row }) =>
            formatToTwoDecimalPlaces(Number(row.original?.amount)),
    },
    {
        accessorKey: "tip",
        header: "Tip",
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
        Cell: ({ row }) => formatToTwoDecimalPlaces(Number(row.original?.tip)),
    },
    {
        accessorKey: "pickup_name",
        header: "Pickup Location",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "dropoff_name",
        header: "Dropoff Location",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "pickup_time",
        header: "Pickup Time",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue())?.toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "dropoff_time",
        header: "Dropoff Time",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue())?.toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "program_name",
        header: "Program",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
        Cell: ({ row }) => (
            <Button
                disableElevation
                variant="contained"
                color="common"
                sx={{ py: ".5em", px: ".5em", borderRadius: "1em" }}
            >
                {row.original?.program_name}
            </Button>
        ),
    },
    {
        accessorKey: "created_at",
        header: "Date",
        size: 200,
        filterVariant: "date",
        enableSorting: false,
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "view",
        header: " ",
        size: 200,
    },
]

function PeopleTransactions() {
    const { transactionsList, transactionsPage, transactionsView } =
        useUserPermissions()
    const { params, state, onChangeHandlers } = useTableState({
        columns: transactions,
    })
    const [detail, setDetail] = useState(false)

    const { state: userInfo } = useLocation()

    // THIS IS TO FILTER TRANSACTIONS BY USER
    const userFilter = {
        column_field: "passenger_id",
        operator_value: "contains",
        value: userInfo.user_id,
    }

    const { filterModel, ...rest } = params
    const newFilterModel = [...filterModel, userFilter]

    const handleTransactionView = useMemo(
        () => (row) => {
            setDetail({ ...row })
        },
        []
    )

    const exportProcessor = (data) =>
        data?.map((el) => ({
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            driver_name: el.detail?.driver_name,
            driver_phone: el.detail?.driver_phone,
            received_from: el.detail?.passenger_name,
            user_phone: el.detail?.passenger_phone,
            amount: el.detail?.amount,
            tip: el.detail?.tip,
            pickup_name: el.detail?.pickup_name,
            dropoff_name: el.detail?.dropoff_name,
            pickup_time: DateTime.fromISO(el.detail?.pickup_time).toFormat(
                "ff"
            ),
            dropoff_time: DateTime.fromISO(el.detail?.dropoff_time).toFormat(
                "ff"
            ),
            program: el.detail?.program_name,
            note: el.note,
        }))

    const {
        data: Tdata,
        isFetching,
        isLoading,
    } = useFetchEntitiesPerPage(
        {
            endPoint: "program_transactions",
            filterModel: newFilterModel,
            ...rest,
        },
        { enabled: transactionsList }
    )

    const actionColumn = useCallback(
        ({ row }) => (
            <IconButton
                disabled={!transactionsView}
                onClick={() => handleTransactionView(row.original)}
                sx={{ color: "primary.main" }}
            >
                <Visibility />
            </IconButton>
        ),
        [handleTransactionView, transactionsView]
    )

    const colDefs = useMemo(
        () =>
            transactions.map((el) => {
                if (el.accessorKey === "view") {
                    return {
                        ...el,
                        Cell: actionColumn,
                    }
                }
                return el
            }),
        [actionColumn]
    )

    const { props } = useTableProps({
        columns: colDefs,
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
        exportProps: {
            dataEndPoint: `program_transactions`,
            model: newFilterModel,
            formatter: exportProcessor,
            pageFilter: [userFilter],
        },
    })

    if (!transactionsPage) return <RestrictedAccess />

    return (
        <Box sx={{ backgroundColor: "white" }}>
            {transactionsView && (
                <ViewTripDetails detail={detail} setDetail={setDetail} />
            )}
            <MaterialReactTable
                data={
                    Tdata?.data?.data?.map((el) => ({
                        created_at: el.created_at,
                        note: el.note,
                        ...el.detail,
                    })) ?? []
                }
                rowCount={Tdata?.data?.meta_data?.total ?? 0}
                initialState={{
                    columnPinning: { left: ["name"], right: ["status"] },
                }}
                {...props}
                {...onChangeHandlers}
            />
        </Box>
    )
}

export default PeopleTransactions
