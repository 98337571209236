import { useEffect } from "react"
import { useMutation } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "../Utils/Api/axios.js"
import useAuth from "../Hooks/useAuth.js"
import { BMS_BASE_URL } from "../Utils/config.js"
/// ?code=sldfjsdlkjf&session_state=sdfjsldfj
// ?error=sldkfjsdlfj&error_description=sldkfjdlfj

function Authorize() {
    const navigate = useNavigate()
    const location = useLocation()
    const { setAuth } = useAuth()

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    })

    const { mutate } = useMutation((code) =>
        axios.post("/auth/token", {
            code: code,
            redirect_uri: `${BMS_BASE_URL}/authorize`,
            grant_type: "authorization_code",
        })
    )

    useEffect(() => {
        if (params.session_state) {
            localStorage.setItem("session_state", params.session_state)
        }
        if (params.code) {
            mutate(params.code, {
                onSuccess: (data) => {
                    localStorage.setItem(
                        "refresh token",
                        data?.data?.data?.refresh_token
                    )
                    // if (location.pathname !== "/IPIFrame") {
                    setAuth(data?.data?.data)
                    navigate("/")
                    window.parent.postMessage("login-success", "*")
                    // }
                },
                onError: (error) => {
                    console.error({
                        error,
                        msg: error?.response?.data?.error?.message,
                        default: error?.message,
                    })
                },
            })
        }
        if (params.error) {
            localStorage.removeItem("refresh token")
            window.parent.postMessage("login-fail", "*")
        }
    }, [
        location.pathname,
        mutate,
        navigate,
        params.code,
        params.error,
        params.sessionState,
        params.session_state,
        setAuth,
    ])
}
export default Authorize
