import { useSnackbar } from "notistack"
import { useContext } from "react"
import { useQuery } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"

// Fetch/Get All Entities List Per-Page, with Filter, Search and Sort Preferences Hook
const useFetchEntitiesPerPage = (props, options) => {
    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)
    const { endPoint, page, perPage, filterModel, sortModel, searchText } =
        props

    const { enqueueSnackbar } = useSnackbar()

    return useQuery({
        queryKey: [
            companyId,
            endPoint,
            page,
            perPage,
            filterModel,
            sortModel?.length,
            filterModel?.length,
            sortModel,
            undefined,
            searchText,
        ],
        queryFn: () =>
            axiosPrivate
                .get(`/corporate/${companyId}/${endPoint}`, {
                    params: {
                        page: page,
                        per_page: perPage,
                        sort:
                            sortModel?.length === 0
                                ? undefined
                                : JSON.stringify(sortModel),
                        search: searchText,
                        filter:
                            filterModel?.length === 0
                                ? []
                                : JSON.stringify(filterModel),
                        link_operator: "and",
                    },
                })
                .then((response) => response),

        onError: (error) => {
            const fieldError =
                error.response?.data?.error?.field_error?.[0]?.description
            const errorMessage = error.response?.data?.error?.message
            enqueueSnackbar(fieldError || errorMessage || "Request Failed", {
                variant: "error",
            })
        },
        ...options,
    })
}

export default useFetchEntitiesPerPage
