/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from "react"
import { merge } from "lodash"
import { Box, Button, CircularProgress, IconButton } from "@mui/material"
import {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { Replay } from "@mui/icons-material"
import { useQueryClient } from "react-query"
import ExportCustome from "../Component/exportCustom.jsx"
import CustomeTabs from "../Component/customeTabs.jsx"
import AuthContext from "../Context/auth_provider.jsx"

export function ActionButton({ onClick, actionName }) {
    return (
        <Button
            size="small"
            sx={{
                bgcolor: "primary.main",
                color: "white",
                px: 2,
                boxShadow: 2,
                " :hover": {
                    bgcolor: "primary.main",
                    color: "white",
                },
            }}
            onClick={() => onClick()}
        >
            {actionName}
        </Button>
    )
}

const useTableProps = ({
    columns,
    actionButtonProps,
    tabProps,
    state,
    exportProps,
    ...rest
}) => {
    const { dataEndPoint, model, formatter, pageFilter } = exportProps || {}
    const { actionName, onClick } = actionButtonProps || {}
    const { tabs, onChange } = tabProps || {}
    const { showAlertBanner, showProgressBars } = state || {}

    const noFilterableColumn = columns?.every(el => (el.enableColumnFilter !== undefined) && !el.enableColumnFilter )

    const queryClient = useQueryClient()
    const { companyId } = useContext(AuthContext)

    const props = {
        columns: columns,
        enableColumnFilterModes: true,
        enableStickyHeader: true,
        enableGlobalFilter: true,
        enablePinning: true,
        enableColumnOrdering: true,
        enableRowSelection: true,
        enableColumnResizing: true,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiLinearProgressProps: ({ isTopToolbar }) => ({
            sx: { display: isTopToolbar ? "block" : "none" },
        }),
        muiTableContainerProps: () => ({
            sx: {
                maxHeight: "calc(100vh - 250px)",
            },
        }),
        muiTableHeadCellProps: ({ table, column }) => {
            const columnName = column.id
            const pinnedState = table.getState().columnPinning

            const isLeftPinned = pinnedState?.left?.some(
                (el) => el === columnName
            )
            const isRightPinned = pinnedState?.right?.[0] === columnName

            if (isLeftPinned || isRightPinned)
                return {
                    sx: {
                        "&.MuiTableCell-root": {
                            boxShadow: isRightPinned
                                ? "-6px 0px 10px -1.7px lightgray"
                                : "6px 0px 10px -1.7px lightgray",
                        },
                    },
                }
            return null
        },
        muiTableBodyCellProps: ({ table, column }) => {
            const columnName = column.id
            const pinnedState = table.getState().columnPinning

            const isLeftPinned = pinnedState?.left?.some(
                (el) => el === columnName
            )

            const isRightPinned = pinnedState?.right?.[0] === columnName

            if (isLeftPinned || isRightPinned)
                return {
                    sx: {
                        "&.MuiTableCell-root": {
                            boxShadow: isRightPinned
                                ? "-6px 0px 9px -1.7px lightgray"
                                : "6px 0px 9px -1.7px lightgray",
                        },
                    },
                }
            return null
        },
        muiTopToolbarProps: {
            sx: {
                "& > :nth-child(3)": {
                    display: { xs: "flex" },
                    flexDirection: { xs: "column", md: "row" },
                },
            },
        },
        renderToolbarInternalActions: ({ table }) => (
            <>
                <IconButton
                    onClick={() =>
                        queryClient.invalidateQueries({ queryKey: [companyId] })
                    }
                >
                    {showProgressBars ? (
                        <CircularProgress size="1em" sx={{p: '0px', m: '0px'}} />
                    ) : (
                        <Replay />
                    )}
                </IconButton>
                {!noFilterableColumn && <MRT_ToggleGlobalFilterButton table={table} />}
                {exportProps && (
                    <ExportCustome
                        table={table}
                        endPoint={dataEndPoint}
                        model={model}
                        formatter={formatter}
                        pageFilter={pageFilter}
                    />
                )}
                {!noFilterableColumn && <MRT_ToggleFiltersButton table={table} />}
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_FullScreenToggleButton table={table} />
            </>
        ),
        renderTopToolbarCustomActions: () => (
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {actionButtonProps && (
                        <ActionButton
                            onClick={onClick}
                            actionName={actionName}
                        />
                    )}
                </Box>
                {tabProps && <CustomeTabs tabs={tabs} onChange={onChange} />}
            </Box>
        ),
        muiToolbarAlertBannerProps: showAlertBanner
            ? {
                  color: "error",
                  children: "Error loading data",
              }
            : undefined,
        state,
    }

    const mergedProps = merge(props, rest)

    return {
        props: mergedProps,
    }
}

export default useTableProps
