import React from "react"
import { Box, CircularProgress, Typography } from "@mui/material"

function LoadingPage({ message }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography variant="body2">{message}</Typography>
            <CircularProgress />
        </Box>
    )
}

export default LoadingPage
