import React, { useEffect, useState } from "react"
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { Controller, useForm } from "react-hook-form"
import { ContentCopy } from "@mui/icons-material"

import useCreateEntity from "../../Hooks/useCreateEntity.js"
import CustomDateTimePicker from "../../Component/cutomDateTimePicker.jsx"

// Generate an Api Key Component
function GenerateApiKey({ handleClose, open, refetch }) {
    const [copy, setCopy] = useState(false)
    const [generated, setGenerated] = useState(false)
    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onChange",
    })
    console.log("err", errors)
    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERIES $ MUTATIONS
    const { mutate, isLoading, data: apiKey, error } = useCreateEntity(`apikey`)

    // HELPERS & EVENT HANDLERS
    const onSubmit = (formData) => {
        mutate(formData)
    }
    const handleDialogClose = () => {
        if (generated) {
            handleClose()
            refetch()
        } else handleClose()
    }

    // USEFFECTS
    useEffect(() => {
        if (apiKey) {
            enqueueSnackbar(`Succesfully created a new api key.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            setGenerated(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiKey])

    useEffect(() => {
        if (error) {
            const fieldError =
                error.response?.data?.error?.field_error?.[0]?.description
            const errorMessage = error.response?.data?.error?.message

            enqueueSnackbar(fieldError || errorMessage || "Request Failed", {
                variant: "error",
            })
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    // RENDER
    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {generated ? "Generated API Key" : "Generate API Token"}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        px: 2,
                        mt: 2,
                    }}
                >
                    {!generated ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Name is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Name"
                                        autoComplete="name"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.name}
                                        helperText={
                                            errors?.name
                                                ? errors.name.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mb: 1,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="expire_at"
                                control={control}
                                rules={{
                                    required: "Expire date is required",
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CustomDateTimePicker
                                        onChange={onChange}
                                        label="Expire Date"
                                        value={value}
                                        date2={DateTime.now().toISO()}
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1em",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                    },
                                    gap: "0.5em",
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    value="***********"
                                    disabled={copy}
                                    size="small"
                                    sx={{
                                        mb: 1,
                                    }}
                                />
                                {!copy && (
                                    <Box>
                                        <Tooltip title="Click to copy">
                                            <IconButton
                                                variant="outlined"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        apiKey?.data?.data
                                                            ?.api_key
                                                    )
                                                    setCopy({ copied: true })
                                                }}
                                            >
                                                <ContentCopy />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                            {copy ? (
                                <Alert variant="outlined" severity="success">
                                    Success! <br />
                                    You have successfully copied the API key.
                                    You are all set to proceed with accessing
                                    the API.
                                </Alert>
                            ) : (
                                <Alert variant="outlined" severity="info">
                                    Please ensure you copy the generated api
                                    key. This is the only time you will have
                                    access to it.
                                </Alert>
                            )}
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mb: 1,
                    pr: 4,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        color: "primary.main",
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleDialogClose}
                >
                    <Typography>{generated ? "Close" : "Cancel"}</Typography>
                </Button>
                {!generated && (
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            bgcolor: "primary.main",
                            ":hover": { bgcolor: "primary.main" },
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                    >
                        <Typography>Generate</Typography>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "common.main",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
export default GenerateApiKey
