import React, { useEffect, useMemo, useState, useCallback, useRef } from "react"
import {
    CheckCircleOutline,
    ContentCopyOutlined,
    Edit,
    PeopleOutlined,
    Visibility,
} from "@mui/icons-material"
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material"
import MaterialReactTable from "material-react-table"
import { Link } from "react-router-dom"
import { useSnackbar } from "notistack"
import { DateTime } from "luxon"

import useTableState from "../../Hooks/useTableState.js"
import useTableProps from "../../Hooks/useTableProps.js"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import usePartialUpdateEntity from "../../Hooks/usePartialUpdateEntity.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"

import StatusButton from "../../Component/statusButton.jsx"
import customeFilter from "../../Component/customFilterPanel.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import CreateDiscount from "./addDiscount.jsx"
import UpdateDiscount from "./updateDiscount.jsx"
import DiscountDetails from "./discountDetails.jsx"
import DownloadQRCode from "./downloadDiscountQrCode.jsx"

const discountFields = [
    {
        accessorFn: (row) => DateTime.fromISO(row.created_at).toFormat("ff"),
        id: "created_at",
        header: "Created At",
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 240,
    },
    {
        accessorKey: "name",
        header: "Name",
        size: 240,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "code",
        header: "Code",
        size: 240,
        enableSorting: false,
        enableColumnFilter: false,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "percentage",
        header: "Percentage (%)",
        filterVariant: "number",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "user_limit",
        header: "User Limit",
        filterVariant: "number",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "expire_date",
        header: "Expire Date",
        filterVariant: "date",
        size: 240,
        muiTableHeadCellFilterTextFieldProps: ({ column }) => ({
            type: "date",
            onChange: (event) => {
                column?.setFilterValue(event?.target?.value)
            },
        }),
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => {
            const expired =
                DateTime.now().startOf("hour") >
                DateTime.fromISO(cell.getValue()).startOf("hour")
            return expired ? (
                <Button
                    size="small"
                    disableElevation
                    variant="contained"
                    sx={{
                        backgroundColor: "#ffd6d6",
                        color: "red",
                        "&: hover": {
                            backgroundColor: "#ffd6d6",
                            color: "red",
                        },
                    }}
                >
                    Expired
                </Button>
            ) : (
                DateTime.fromISO(cell.getValue()).toFormat("ff")
            )
        },
    },
    {
        accessorFn: (row) => DateTime.fromISO(row.created_at).toFormat("ff"),
        id: "updated_at",
        header: "Recent Activity",
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 240,
    },
    {
        accessorKey: "status",
        header: "Actions",
        size: 380,
        filterVariant: "select",
        filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
        renderColumnFilterModeMenuItems: customeFilter,
    },
]

function Discounts() {
    const { generateDiscount, discountsList, discountsUpdate, discountUsers } =
        useUserPermissions()

    const { md } = useBreakPoints()

    const { params, state, onChangeHandlers } = useTableState({
        columns: discountFields,
    })

    const [total, setTotal] = useState(0)
    const [open, setOpen] = useState(false)
    const [update, setUpdate] = useState(false)
    const [view, setView] = useState(false)
    const [copy, setCopy] = useState({ status: false, code: undefined })

    const discountData = useRef()
    const { enqueueSnackbar } = useSnackbar()

    const { filterModel, ...rest } = params
    const { data, isLoading, isFetching, refetch } = useFetchEntitiesPerPage(
        {
            endPoint: "discounts",
            filterModel: params.filterModel,
            ...rest,
        },
        {
            enabled: discountsList,
        }
    )
    // TO KEEP THE PAGINATION STATE WHEN API ERRORS OUT
    useEffect(() => {
        setTotal((prevTotal) => (total !== undefined ? total : prevTotal))
    }, [total, setTotal])

    // HOOK TO UPDATE DISCOUNTS, USED FOR STATUS CHANGE HERE
    const { mutate, isLoading: updating } = usePartialUpdateEntity(`discount`)

    // HANDLERS
    const handleStatusChange = useCallback(
        (checked, row) => {
            mutate(
                {
                    id: row?.id,
                    status: checked ? "ACTIVE" : "INACTIVE",
                },
                {
                    onError: (error) => {
                        enqueueSnackbar(
                            `Status update failed due to ${error.message}`,
                            { variant: "error" }
                        )
                    },
                    onSuccess: () => {
                        enqueueSnackbar("Status updated successfully", {
                            variant: "success",
                        })
                        refetch()
                    },
                }
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )
    const customDateFilter = useCallback(
        (props) => <CustomeDateFilter {...props} />,
        []
    )
    const actionColumn = useCallback(
        ({ cell, row }) => (
            <Box
                sx={{
                    display: "flex",
                    gap: "1.5em",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <StatusButton
                    loading={updating}
                    status={cell.getValue()}
                    onChange={(checked) =>
                        handleStatusChange(checked, row.original)
                    }
                />
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                    }}
                >
                    <Tooltip title="View Details">
                        <IconButton
                            size="small"
                            sx={{ color: "primary.main" }}
                            onClick={() => {
                                discountData.current = cell?.row?.original
                                setView(true)
                            }}
                        >
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                    {discountsUpdate && (
                        <Tooltip title="edit">
                            <IconButton
                                size="small"
                                sx={{ color: "primary.main" }}
                                onClick={() => {
                                    discountData.current = cell?.row?.original
                                    setUpdate(true)
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    )}
                    {discountUsers && (
                        <Link to={`${row.original.id}/users`}>
                            <Tooltip title="Discount Users">
                                <IconButton
                                    size="small"
                                    sx={{ color: "primary.main" }}
                                >
                                    <PeopleOutlined />
                                </IconButton>
                            </Tooltip>
                        </Link>
                    )}
                    <Tooltip title="Generate & dowload QR Code">
                        <DownloadQRCode qrCodeText={row.original.code} />
                    </Tooltip>
                </Box>
            </Box>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updating, discountUsers, discountsUpdate]
    )
    const copyDiscountCode = useCallback(
        ({ cell }) => (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {
                        xs: "column",
                        sm: "row",
                    },
                    gap: "0.5em",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
                key={cell.getValue()}
            >
                <Box sx={{ justifyContent: "start" }}>
                    <Typography
                        textAlign="center"
                        fontWeight="bold"
                        variant="h5"
                    >
                        *****
                    </Typography>
                </Box>
                {copy.status && copy.code === cell.getValue() ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.1em",
                            color: "#57d246",
                        }}
                    >
                        <Typography
                            textAlign="center"
                            fontWeight="bold"
                            variant="body2"
                        >
                            Copied!
                        </Typography>
                        <CheckCircleOutline
                            variant="success"
                            sx={{ alignSelf: "center" }}
                        />
                    </Box>
                ) : (
                    <Box>
                        <Tooltip title="Click to copy">
                            <IconButton
                                variant="outlined"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        cell.getValue()
                                    )
                                    setCopy({
                                        status: true,
                                        code: cell.getValue(),
                                    })
                                }}
                            >
                                <ContentCopyOutlined
                                    sx={{ color: "primary.main" }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>
        ),
        [copy]
    )

    // ADD A RENDER METHOD TO `STATUS `COL DEFINITION
    const colFields = useMemo(
        () =>
            discountFields.map((ele) => {
                if (
                    ele.id === "created_at" ||
                    ele.accessorKey === "expire_date" ||
                    ele.id === "updated_at"
                )
                    return {
                        ...ele,
                        Filter: customDateFilter,
                    }
                if (ele.accessorKey === "code") {
                    return {
                        ...ele,
                        Cell: copyDiscountCode,
                    }
                }
                if (ele.accessorKey === "status")
                    return {
                        ...ele,
                        Cell: actionColumn,
                    }
                return ele
            }),
        [customDateFilter, actionColumn, copyDiscountCode]
    )
    const handleDiscountsExport = (exportData) =>
        exportData?.map((el) => ({
            ...el,
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            updated_at: DateTime.fromISO(el.updated_at).toFormat("ff"),
            expire_date: DateTime.fromISO(el.expire_date).toFormat("ff"),
        }))

    const { props } = useTableProps({
        columns: colFields,
        actionButtonProps: generateDiscount && {
            actionName: "Generate Discount",
            onClick: () => setOpen(true),
        },
        exportProps: {
            dataEndPoint: "discounts",
            model: params.filterModel,
            formatter: handleDiscountsExport,
        },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
    })

    useEffect(() => {
        if (data?.data?.meta_data.total) setTotal(data?.data?.meta_data.total)
    }, [data])
    useEffect(() => {
        if (copy.status) {
            setTimeout(() => setCopy({ status: false, code: undefined }), 2000)
        }
    }, [copy.status])

    if (!discountsList) return <RestrictedAccess />

    return (
        <Box>
            {open && (
                <CreateDiscount
                    open={open}
                    refetch={refetch}
                    handleClose={() => setOpen(false)}
                />
            )}
            {view && (
                <DiscountDetails
                    open={view}
                    handleClose={() => setView(false)}
                    discountData={discountData.current}
                />
            )}
            {update && (
                <UpdateDiscount
                    open={update}
                    refetch={refetch}
                    handleClose={() => setUpdate(false)}
                    discountData={discountData.current}
                />
            )}
            <Box
                sx={{
                    backgroundColor: "white",
                }}
            >
                <MaterialReactTable
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            right: [md ? "status" : null],
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    {...props}
                    {...onChangeHandlers}
                />
            </Box>
        </Box>
    )
}

export default Discounts
