import axios from "axios"
import { BASE_URL, VERSION } from "../config.js"

export default axios.create({
    baseURL: `${BASE_URL}/${VERSION}`,
})
export const axiosPrivate = axios.create({
    baseURL: `${BASE_URL}/${VERSION}`,
    headers: { "Content-Type": "application/json" },
})
