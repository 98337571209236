import React, { useEffect, useRef, useState } from "react"
import { Clear, FilterList } from "@mui/icons-material"
import { Box, IconButton, Menu } from "@mui/material"
import { DateTime } from "luxon"
import CustomeDatePicker from "./datePicker.jsx"

export function FilterMenu({ open, setOpen, anchorEl, children }) {
    return (
        <Menu anchorEl={anchorEl} open={open} onClose={() => setOpen(false)}>
            {children}
        </Menu>
    )
}

function CustomeDateFilter(props) {
    const { table, column } = props

    const [open, setOpen] = useState(false)
    const [children, setChildren] = useState(null)
    const [value, setValue] = useState({
        value1: null,
        value2: null,
    })

    const ref = useRef()

    const filterValue = column.getFilterValue()

    useEffect(() => {
        if (
            table.getState().columnFilterFns[column.id] === "inBetween" &&
            value.value1 !== null &&
            value.value2 !== null
        ) {
            column.setFilterValue(`${value?.value1}|${value?.value2}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.value1, value.value2])

    useEffect(() => {
        if (
            table.getState().columnFilterFns[column.id] !== "inBetween" &&
            filterValue?.length > 12
        ) {
            setValue({
                value1: null,
                value2: null,
            })
            column.setFilterValue(undefined)
        }
        if (
            table.getState().columnFilterFns[column.id] === "inBetween" &&
            (filterValue?.length < 12 || value.value1 === null)
        ) {
            column.setFilterValue(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue?.length, value.value1])

    return (
        <Box ref={ref} sx={{ display: "flex", flexDirection: "column" }}>
            <Box ref={ref} sx={{ display: "flex", alignItems: "center" }}>
                <FilterMenu
                    open={open}
                    setOpen={setOpen}
                    anchorEl={ref.current}
                >
                    {children}
                </FilterMenu>
                <CustomeDatePicker
                    disableFuture
                    variant="standard"
                    maxDate={value?.value2}
                    value={
                        filterValue === undefined || filterValue?.length > 12
                            ? value?.value1
                            : filterValue
                    }
                    onChange={(date) =>
                        table.getState().columnFilterFns[column.id] ===
                        "inBetween"
                            ? setValue((prev) => ({
                                  value1: DateTime.fromISO(date).toFormat(
                                      "yyyy-LL-dd"
                                  ),
                                  value2: prev?.value2,
                              }))
                            : column.setFilterValue(
                                  DateTime.fromISO(date).toFormat("yyyy-LL-dd")
                              )
                    }
                    size="small"
                    helperText={
                        table.getState().columnFilterFns[column.id] ===
                        "inBetween"
                            ? " "
                            : `Filter Mode: ${
                                  table.getState().columnFilterFns[column.id]
                              }`
                    }
                    InputProps={{
                        startAdornment: (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setChildren(
                                        column.columnDef.renderColumnFilterModeMenuItems(
                                            {
                                                column,
                                                onSelectFilterMode: (fmode) => {
                                                    table.setColumnFilterFns({
                                                        ...table.getState()
                                                            .columnFilterFns,
                                                        [column.id]: fmode,
                                                    })
                                                    setOpen(false)
                                                },
                                            }
                                        )
                                    )
                                    setOpen(true)
                                }}
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                            >
                                <FilterList />
                            </IconButton>
                        ),
                    }}
                />
                <IconButton
                    onClick={() => {
                        column.setFilterValue(undefined)
                        setValue((prev) => ({
                            value1: null,
                            value2: prev?.value2,
                        }))
                    }}
                    sx={{ mb: ".3em" }}
                >
                    <Clear />
                </IconButton>
            </Box>
            <Box
                key={`${column.id}_${
                    table.getState().columnFilterFns[column.id]
                }`}
                ref={ref}
                sx={{ display: "flex", alignItems: "center" }}
            >
                {table.getState().columnFilterFns[column.id] ===
                    "inBetween" && (
                    <>
                        <CustomeDatePicker
                            disableFuture
                            variant="standard"
                            value={value?.value2}
                            minDate={value?.value1}
                            onChange={(date) => {
                                setValue((prev) => ({
                                    value1: prev?.value1,
                                    value2: DateTime.fromISO(date).toFormat(
                                        "yyyy-LL-dd"
                                    ),
                                }))
                            }}
                            size="small"
                            helperText="Filter Mode: between"
                            InputProps={{
                                startAdornment: (
                                    <Box
                                        size="small"
                                        sx={{
                                            width: "34px",
                                            height: "34px",
                                            px: 2,
                                        }}
                                    />
                                ),
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                column.setFilterValue(undefined)
                                setValue((prev) => ({
                                    value1: prev?.value1,
                                    value2: null,
                                }))
                            }}
                            sx={{ mb: ".3em" }}
                        >
                            <Clear />
                        </IconButton>
                    </>
                )}
            </Box>
        </Box>
    )
}

export default CustomeDateFilter
