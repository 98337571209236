import { BMS_BASE_URL, SSO_API_BASE_URL } from "./config.js"

const LoginRedirect = (prompt) => {
    window.location.href = `${SSO_API_BASE_URL}/v1/oauth/authorize?client_id=6f64e789-1d24-4498-a089-838a7c021270&redirect_uri=${BMS_BASE_URL}/authorize&scope=openid&state=state&response_type=code&prompt=${prompt}`
    // window.location.href = `http://localhost:8000/v1/oauth/authorize?client_id=0e3e21f0-5107-4610-ba4d-c42653119f2a&redirect_uri=http://localhost:3000/authorize&scope=openid&state=state&response_type=code&prompt=${prompt}`
}

export default {
    LoginRedirect,
}
