import React, { useCallback, useContext, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { Box } from "@mui/material"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { useQueryClient } from "react-query"
import MaterialReactTable from "material-react-table"
import useTableState from "../../Hooks/useTableState.js"
import useTableProps from "../../Hooks/useTableProps.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import customeFilter from "../../Component/customFilterPanel.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import StatusButton from "../../Component/statusButton.jsx"
import usePartialUpdateEntity from "../../Hooks/usePartialUpdateEntity.js"
import AuthContext from "../../Context/auth_provider.jsx"

const users = [
    {
        accessorKey: "first_name",
        header: "Name",
        width: 200,
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ row }) =>
            `${row.original.first_name} ${row.original.last_name} ${row.original.middle_name}`,
    },
    {
        accessorKey: "phone",
        header: "Phone No",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => `+${cell.getValue()}`,
    },
    {
        accessorKey: "monthly_spent_amount",
        header: "Monthly Amount Spent",
        size: 150,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "total_spent_amount",
        header: "Total Amount Spent",
        size: 150,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
    },
    // {
    //     accessorKey: "voucher",
    //     header: "Voucher",
    //     renderColumnFilterModeMenuItems: customeFilter,
    //     Cell: ({ row }) => row.original?.voucher_code,
    // },
    {
        accessorKey: "user_program_status",
        header: "Status",
        size: 320,
        filterVariant: "select",
        filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "created_at",
        header: "Created At",
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Filter: (props) => <CustomeDateFilter {...props} />,
        Cell: ({ cell }) =>
            `${DateTime.fromISO(cell.getValue()).toFormat("ff")}`,
    },
]

function ProgramUsers() {
    const { programsUsersList } = useUserPermissions()
    const {
        params,
        state: tableState,
        onChangeHandlers,
    } = useTableState({
        columns: users,
    })

    // SPECIFIC PROGRAM ID, COMES FROM THE PROGRAMS TABLE
    const { state } = useLocation()
    const programId = state.id

    // HOOK TO UPDATE PROGRAMS, USED FOR STATUS CHANGE HERE
    const { mutate, isLoading: updating } =
        usePartialUpdateEntity(`programs/users`)

    const { enqueueSnackbar } = useSnackbar()
    const { companyId } = useContext(AuthContext)
    const queryClient = useQueryClient()

    // HANDLERS
    const handleStatusChange = useCallback(
        (checked, row) => {
            mutate(
                {
                    id: row?.id,
                    program_id: programId,
                    status: checked ? "ACTIVE" : "INACTIVE",
                },
                {
                    onError: (error) => {
                        enqueueSnackbar(
                            `status update failed due to ${error.message}`,
                            { variant: "error" }
                        )
                    },
                    onSuccess: () => {
                        enqueueSnackbar("status updated successfully", {
                            variant: "success",
                        })
                        queryClient.invalidateQueries({
                            queryKey: [
                                companyId,
                                `programs/${programId}/users`,
                            ],
                        })
                    },
                }
            )
        },
        [mutate, enqueueSnackbar, programId, companyId, queryClient]
    )

    const actionColumn = useCallback(
        ({ cell, row }) => (
            <StatusButton
                loading={updating}
                status={cell.getValue() ? "ACTIVE" : "INACTIVE"}
                onChange={(checked) =>
                    handleStatusChange(checked, row.original)
                }
            />
        ),
        [handleStatusChange, updating]
    )

    const colFields = useMemo(
        () =>
            users?.map((ele) => {
                if (ele.accessorKey === "user_program_status")
                    return {
                        ...ele,
                        Cell: actionColumn,
                    }
                return ele
            }),
        [actionColumn]
    )

    const { data, isLoading, isFetching } = useFetchEntitiesPerPage({
        endPoint: `programs/${programId}/users`,
        ...params,
    })

    const { props } = useTableProps({
        columns: colFields,
        exportProps: {
            dataEndPoints: `programs/${programId}/users`,
            model: params.filterModel,
        },
        state: {
            ...tableState,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
    })

    if (!programsUsersList) return <RestrictedAccess />

    return (
        <Box sx={{ backgroundColor: "white" }}>
            <MaterialReactTable
                data={data?.data?.data || []}
                rowCount={data?.data?.meta_data?.total ?? 0}
                initialState={{
                    columnPinning: { right: ["status"] },
                }}
                {...onChangeHandlers}
                {...props}
            />
        </Box>
    )
}

export default ProgramUsers
